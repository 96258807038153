import { useEffect, useState } from "react";
import {
  makeAjax,
  url,
  showMessage,
  respStatus,
} from "../../helpers/global_helper";
import { formatPricewithdecimal } from "../NumberFormat";
import { withTranslation } from "react-i18next";
import modalSrc from "../../assets/images/modal/close.svg";
const Bonus = (props) => {
  const [bonus, setBonus] = useState("");
  const [bonusName, setBonusName] = useState("");
  const [showBonusPopup, setShowBonusPopup] = useState(false); 

  const fetchBonus = () => {
    let reqData = {};
    makeAjax(
      url.PLAYER_API.getUserEventBonusCoin,
      reqData,
      url.PLAYER_MS_EXT
    ).then((response) => {
      // console.log("=> Bonus API Response", response.data);
      if (response.status !== respStatus["SUCCESS"]) {
        if (response.code !== "validation_error") {
          showMessage("Server error: No data found", "error", "error");
          return;
        }
        showMessage("Server error: No data found", "error", "error");
        return;
      }

      setBonus(response.data.value);
      setBonusName(response.data.name);
      if (response.data.value) {
        setShowBonusPopup(true);
      } else {
        setShowBonusPopup(false);
      }
    });
  };

  useEffect(() => {
    fetchBonus();
  }, []);

  return (
    <>
      {showBonusPopup && (
        <div className="bonus-error">
          <div className="bonus-error-popup">
            <p className="heading">{props.t("message")}</p>
            <span className="bonus-close" onClick={() => setShowBonusPopup(false)}><img src={modalSrc} alt="modal icon" /></span>
            <p className="error-message">
              {props.t('txt_event_manual_popup_1')}: {formatPricewithdecimal(bonus)}</p>
              <p className="promo-txt">{props.t('txt_event_manual_popup_2')}: {bonusName}</p>            
            <p className="bonus-btm">{props.t('txt_event_manual_popup_3')}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(Bonus);
