// 52 cards import
import Heart_A from "../../../assets/images/card_images/HEART/A_HEART.png";
import Heart_2 from "../../../assets/images/card_images/HEART/2_HEART.png";
import Heart_3 from "../../../assets/images/card_images/HEART/3_HEART.png";
import Heart_4 from "../../../assets/images/card_images/HEART/4_HEART.png";
import Heart_5 from "../../../assets/images/card_images/HEART/5_HEART.png";
import Heart_6 from "../../../assets/images/card_images/HEART/6_HEART.png";
import Heart_7 from "../../../assets/images/card_images/HEART/7_HEART.png";
import Heart_8 from "../../../assets/images/card_images/HEART/8_HEART.png";
import Heart_9 from "../../../assets/images/card_images/HEART/9_HEART.png";
import Heart_10 from "../../../assets/images/card_images/HEART/10_HEART.png";
import Heart_J from "../../../assets/images/card_images/HEART/J_HEART.png";
import Heart_Q from "../../../assets/images/card_images/HEART/Q_HEART.png";
import Heart_K from "../../../assets/images/card_images/HEART/K_HEART.png";

import Spade_A from "../../../assets/images/card_images/SPADE/A_SPADE.png";
import Spade_2 from "../../../assets/images/card_images/SPADE/2_SPADE.png";
import Spade_3 from "../../../assets/images/card_images/SPADE/3_SPADE.png";
import Spade_4 from "../../../assets/images/card_images/SPADE/4_SPADE.png";
import Spade_5 from "../../../assets/images/card_images/SPADE/5_SPADE.png";
import Spade_6 from "../../../assets/images/card_images/SPADE/6_SPADE.png";
import Spade_7 from "../../../assets/images/card_images/SPADE/7_SPADE.png";
import Spade_8 from "../../../assets/images/card_images/SPADE/8_SPADE.png";
import Spade_9 from "../../../assets/images/card_images/SPADE/9_SPADE.png";
import Spade_10 from "../../../assets/images/card_images/SPADE/10_SPADE.png";
import Spade_J from "../../../assets/images/card_images/SPADE/J_SPADE.png";
import Spade_Q from "../../../assets/images/card_images/SPADE/Q_SPADE.png";
import Spade_K from "../../../assets/images/card_images/SPADE/K_SPADE.png";

import Diamond_A from "../../../assets/images/card_images/DIAMOND/A_DIAMOND.png";
import Diamond_2 from "../../../assets/images/card_images/DIAMOND/2_DIAMOND.png";
import Diamond_3 from "../../../assets/images/card_images/DIAMOND/3_DIAMOND.png";
import Diamond_4 from "../../../assets/images/card_images/DIAMOND/4_DIAMOND.png";
import Diamond_5 from "../../../assets/images/card_images/DIAMOND/5_DIAMOND.png";
import Diamond_6 from "../../../assets/images/card_images/DIAMOND/6_DIAMOND.png";
import Diamond_7 from "../../../assets/images/card_images/DIAMOND/7_DIAMOND.png";
import Diamond_8 from "../../../assets/images/card_images/DIAMOND/8_DIAMOND.png";
import Diamond_9 from "../../../assets/images/card_images/DIAMOND/9_DIAMOND.png";
import Diamond_10 from "../../../assets/images/card_images/DIAMOND/10_DIAMOND.png";
import Diamond_J from "../../../assets/images/card_images/DIAMOND/J_DIAMOND.png";
import Diamond_Q from "../../../assets/images/card_images/DIAMOND/Q_DIAMOND.png";
import Diamond_K from "../../../assets/images/card_images/DIAMOND/K_DIAMOND.png";

import Club_A from "../../../assets/images/card_images/CLUB/A_CLUB.png";
import Club_2 from "../../../assets/images/card_images/CLUB/2_CLUB.png";
import Club_3 from "../../../assets/images/card_images/CLUB/3_CLUB.png";
import Club_4 from "../../../assets/images/card_images/CLUB/4_CLUB.png";
import Club_5 from "../../../assets/images/card_images/CLUB/5_CLUB.png";
import Club_6 from "../../../assets/images/card_images/CLUB/6_CLUB.png";
import Club_7 from "../../../assets/images/card_images/CLUB/7_CLUB.png";
import Club_8 from "../../../assets/images/card_images/CLUB/8_CLUB.png";
import Club_9 from "../../../assets/images/card_images/CLUB/9_CLUB.png";
import Club_10 from "../../../assets/images/card_images/CLUB/10_CLUB.png";
import Club_J from "../../../assets/images/card_images/CLUB/J_CLUB.png";
import Club_Q from "../../../assets/images/card_images/CLUB/Q_CLUB.png";
import Club_K from "../../../assets/images/card_images/CLUB/K_CLUB.png";

import JOCKER from "../../../assets/images/card_images/JOKER.png";
import BackCard from "../../../assets/images/card_images/BACK.png";

// Domino Dice
import DominoDice0 from "../../../assets/images/card_images/Gaple/DMM0.png";
import DominoDice1 from "../../../assets/images/card_images/Gaple/DMM1.png";
import DominoDice2 from "../../../assets/images/card_images/Gaple/DMM2.png";
import DominoDice3 from "../../../assets/images/card_images/Gaple/DMM3.png";
import DominoDice4 from "../../../assets/images/card_images/Gaple/DMM4.png";
import DominoDice5 from "../../../assets/images/card_images/Gaple/DMM5.png";
import DominoDice6 from "../../../assets/images/card_images/Gaple/DMM6.png";
import DominoDice7 from "../../../assets/images/card_images/Gaple/DMM7.png";
import DominoDice8 from "../../../assets/images/card_images/Gaple/DMM8.png";
import DominoDice9 from "../../../assets/images/card_images/Gaple/DMM9.png";
import DominoDice10 from "../../../assets/images/card_images/Gaple/DMM10.png";
import DominoDice11 from "../../../assets/images/card_images/Gaple/DMM11.png";
import DominoDice12 from "../../../assets/images/card_images/Gaple/DMM12.png";
import DominoDice13 from "../../../assets/images/card_images/Gaple/DMM13.png";
import DominoDice14 from "../../../assets/images/card_images/Gaple/DMM14.png";
import DominoDice15 from "../../../assets/images/card_images/Gaple/DMM15.png";
import DominoDice16 from "../../../assets/images/card_images/Gaple/DMM16.png";
import DominoDice17 from "../../../assets/images/card_images/Gaple/DMM17.png";
import DominoDice18 from "../../../assets/images/card_images/Gaple/DMM18.png";
import DominoDice19 from "../../../assets/images/card_images/Gaple/DMM19.png";
import DominoDice20 from "../../../assets/images/card_images/Gaple/DMM20.png";
import DominoDice21 from "../../../assets/images/card_images/Gaple/DMM21.png";
import DominoDice22 from "../../../assets/images/card_images/Gaple/DMM22.png";
import DominoDice23 from "../../../assets/images/card_images/Gaple/DMM23.png";
import DominoDice24 from "../../../assets/images/card_images/Gaple/DMM24.png";
import DominoDice25 from "../../../assets/images/card_images/Gaple/DMM25.png";
import DominoDice26 from "../../../assets/images/card_images/Gaple/DMM26.png";
import DominoDice27 from "../../../assets/images/card_images/Gaple/DMM27.png";
import DominoDice28 from "../../../assets/images/card_images/Gaple/DMM28.png";
import DominoDice29 from "../../../assets/images/card_images/Gaple/DMM29.png";
import DominoDice30 from "../../../assets/images/card_images/Gaple/DMM30.png";
import DominoDice31 from "../../../assets/images/card_images/Gaple/DMM31.png";
import DominoDice32 from "../../../assets/images/card_images/Gaple/DMM32.png";
import DominoDice33 from "../../../assets/images/card_images/Gaple/DMM33.png";
import DominoDice34 from "../../../assets/images/card_images/Gaple/DMM34.png";
import DominoDice35 from "../../../assets/images/card_images/Gaple/DMM35.png";
import DominoDice36 from "../../../assets/images/card_images/Gaple/DMM36.png";
import DominoDice37 from "../../../assets/images/card_images/Gaple/DMM37.png";
import DominoDice38 from "../../../assets/images/card_images/Gaple/DMM38.png";
import DominoDice39 from "../../../assets/images/card_images/Gaple/DMM39.png";
import DominoDice40 from "../../../assets/images/card_images/Gaple/DMM40.png";
import DominoDice41 from "../../../assets/images/card_images/Gaple/DMM41.png";
import DominoDice42 from "../../../assets/images/card_images/Gaple/DMM42.png";

// Export card images
export const cardGameImages = {
  // Heart
  AH: Heart_A,
  "2H": Heart_2,
  "3H": Heart_3,
  "4H": Heart_4,
  "5H": Heart_5,
  "6H": Heart_6,
  "7H": Heart_7,
  "8H": Heart_8,
  "9H": Heart_9,
  "10H": Heart_10,
  JH: Heart_J,
  QH: Heart_Q,
  KH: Heart_K,
  // small Heart
  "1h": Heart_A,
  "2h": Heart_2,
  "3h": Heart_3,
  "4h": Heart_4,
  "5h": Heart_5,
  "6h": Heart_6,
  "7h": Heart_7,
  "8h": Heart_8,
  "9h": Heart_9,
  "10h": Heart_10,
  jh: Heart_J,
  qh: Heart_Q,
  kh: Heart_K,

  // Spade
  AS: Spade_A,
  "2S": Spade_2,
  "3S": Spade_3,
  "4S": Spade_4,
  "5S": Spade_5,
  "6S": Spade_6,
  "7S": Spade_7,
  "8S": Spade_8,
  "9S": Spade_9,
  "10S": Spade_10,
  JS: Spade_J,
  QS: Spade_Q,
  KS: Spade_K,
  // small Spade
  "1s": Spade_A,
  "2s": Spade_2,
  "3s": Spade_3,
  "4s": Spade_4,
  "5s": Spade_5,
  "6s": Spade_6,
  "7s": Spade_7,
  "8s": Spade_8,
  "9s": Spade_9,
  "10s": Spade_10,
  js: Spade_J,
  qs: Spade_Q,
  ks: Spade_K,

  // Diamond
  AD: Diamond_A,
  "2D": Diamond_2,
  "3D": Diamond_3,
  "4D": Diamond_4,
  "5D": Diamond_5,
  "6D": Diamond_6,
  "7D": Diamond_7,
  "8D": Diamond_8,
  "9D": Diamond_9,
  "10D": Diamond_10,
  JD: Diamond_J,
  QD: Diamond_Q,
  KD: Diamond_K,
  // small Diamond
  "1d": Diamond_A,
  "2d": Diamond_2,
  "3d": Diamond_3,
  "4d": Diamond_4,
  "5d": Diamond_5,
  "6d": Diamond_6,
  "7d": Diamond_7,
  "8d": Diamond_8,
  "9d": Diamond_9,
  "10d": Diamond_10,
  jd: Diamond_J,
  qd: Diamond_Q,
  kd: Diamond_K,

  // Club
  AC: Club_A,
  "2C": Club_2,
  "3C": Club_3,
  "4C": Club_4,
  "5C": Club_5,
  "6C": Club_6,
  "7C": Club_7,
  "8C": Club_8,
  "9C": Club_9,
  "10C": Club_10,
  JC: Club_J,
  QC: Club_Q,
  KC: Club_K,
  // small Club
  "1c": Club_A,
  "2c": Club_2,
  "3c": Club_3,
  "4c": Club_4,
  "5c": Club_5,
  "6c": Club_6,
  "7c": Club_7,
  "8c": Club_8,
  "9c": Club_9,
  "10c": Club_10,
  jc: Club_J,
  qc: Club_Q,
  kc: Club_K,

  // Joker
  JK: JOCKER,
  jk: JOCKER,
  JR : JOCKER,
  back : BackCard,
};


export const dominoDiceImage = {
  0: DominoDice0,
  1: DominoDice1,
  2: DominoDice2,
  3: DominoDice3,
  4: DominoDice4,
  5: DominoDice5,
  6: DominoDice6,
  7: DominoDice7,
  8: DominoDice8,
  9: DominoDice9,
  10: DominoDice10,
  11: DominoDice11,
  12: DominoDice12,
  13: DominoDice13,
  14: DominoDice14,
  15: DominoDice15,
  16: DominoDice16,
  17: DominoDice17,
  18: DominoDice18,
  19: DominoDice19,
  20: DominoDice20,
  21: DominoDice21,
  22: DominoDice22,
  23: DominoDice23,
  24: DominoDice24,
  25: DominoDice25,
  26: DominoDice26,
  27: DominoDice27,
  28: DominoDice28,
  29: DominoDice29,
  30: DominoDice30,
  31: DominoDice31,
  32: DominoDice32,
  33: DominoDice33,
  34: DominoDice34,
  35: DominoDice35,
  36: DominoDice36,
  37: DominoDice37,
  38: DominoDice38,
  39: DominoDice39,
  40: DominoDice40,
  41: DominoDice41,
  42: DominoDice42,
};










