import React from 'react'
import PureBig from "../../../../src/assets/images/jackpot_system/DominoQQ/Pure-Big.png";
import PureSmall from "../../../../src/assets/images/jackpot_system/DominoQQ/Pure-Small.png";
import TwinCard from "../../../../src/assets/images/jackpot_system/DominoQQ/Twin-Card.png";
import SixDeity from "../../../../src/assets/images/jackpot_system/DominoQQ/Six-Deity.png";
import { withTranslation } from 'react-i18next';



function DominoQQInfo(props) {
    const { t } = props;
    const injectImages = (htmlString) => {
        // Replace placeholders with image tags
        const processedString = htmlString.replace(
            /\{(PureBig|PureSmall|TwinCard|SixDeity)\}/g,
            (match, key) => {
                const src = images[key];
                return src ? `<img src="${src}" alt="${key}" class="jackpot_sys_info_img" />` : match;
            }
        );

        return processedString;
    };
    const images = {
        PureBig: PureBig,
        PureSmall: PureSmall,
        TwinCard: TwinCard,
        SixDeity: SixDeity,
    };
    const renderedString = injectImages(t('infodmbjs'));
    return (
        <div className="texas-poker-info">
            {/* {injectImages(t('infodmbjs'), images)} */}
            <div dangerouslySetInnerHTML={{ __html: renderedString }} />
        </div>
    );
}

export default withTranslation()(DominoQQInfo);