import { all, fork } from "redux-saga/effects"

//public
import userProfileSaga from "./user-profile/saga";
import userBalanceSaga from "./user-balance/saga";



export default function* rootSaga() {
  yield all([
    fork(userProfileSaga),
    fork(userBalanceSaga),
  ])
}
