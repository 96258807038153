import closeIcon from "../../../src/assets/images/modal/close.svg";

const Modal = ({ onCloseModal, closeButton = true, modalHeader = false, modalTitle = "message", modalSrc = null, children }) => {
    const showModal = true;
    const handleModalClose = () => {
        onCloseModal();
    };
    return (
        <>
            {showModal && (
                <div className="box-overlay">
                    <div className="overlay-div" onClick={handleModalClose}></div>
                    <div className="modal">
                        {modalHeader && (
                            <div className="modal_header">
                                {modalSrc && <div className="modal_icon"><img src={modalSrc} alt="modal icon" /></div>}
                                {modalTitle && <p className="popup_under_text">{modalTitle}</p>}
                                {modalTitle && <div className="header_bottom_border"></div>}
                            </div>
                        )}
                        {closeButton && (
                            <div className="modal_close" onClick={handleModalClose}>
                                <img src={closeIcon} alt="close icon" />
                            </div>
                        )}
                        <div className="modal_body">
                            {children}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
