import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { withTranslation } from "react-i18next";
import { Link, Navigate } from "react-router-dom";
import BannerImg1 from "../../../assets/images/banner/Banner_1.jpg";
import BannerImg2 from "../../../assets/images/banner/Banner_2.jpg";
import BannerImg3 from "../../../assets/images/banner/Banner_3.jpg";
import moBannerImg1 from "../../../assets/images/banner/mo-banner-1.jpg";
import moBannerImg2 from "../../../assets/images/banner/mo-banner-2.jpg";
import moBannerImg3 from "../../../assets/images/banner/mo-banner-3.jpg";
const Carousel = (props) => {

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,            // Enable auto-play
    autoplaySpeed: 3000,       // Auto-play interval (3 seconds)
    pauseOnHover: true,        // Pause auto-play on hover
  };

  const usernameData = [
    { username: "Texas Poker" },
    { username: "Domino QQ" },
    { username: "Ceme" },
    { username: "Ceme Fighter" },
    { username: "Black Jack" },
    { username: "Poker Dealer" },
    { username: "Super 10" },
  ];

  // const Messages = [
  //   `${props.t("welcome")}, Jennifer Flores! Experience the excitement of Texas Poker and test your skills against the best.`,
  //   "Join the thrilling Domino QQ tables and enjoy a game of strategy and luck.",
  //   "Dive into Ceme, where quick thinking and a bit of chance can lead to big wins!",
  //   "Become a Ceme Fighter and conquer the tables with your strategic prowess.",
  //   "Try your luck at Black Jack and aim for the perfect 21 in this classic casino game.",
  //   "Step into the role of a Poker Dealer and control the game with finesse and expertise.",
  //   "Experience the excitement of Super 10, a fast-paced card game that keeps you on your toes.",
  // ];

  const Banners = [
    {
      imgSrc: BannerImg1,
      mobileimgSrc: moBannerImg1,
      link: "http://www.google.com",
    },
    {
      imgSrc: BannerImg2,
      mobileimgSrc: moBannerImg2,
      link: "http://www.google.com",
    },
    {
      imgSrc: BannerImg3,
      mobileimgSrc: moBannerImg3,
      link: "http://www.google.com",
    },

  ]

  return (
    <div className="mobile_carousel">
      <Slider {...settings}>
        {Banners.map((message, index) => {
          const usernameObj = usernameData[index] || {};
          return (
            // <div key={index} className="home_carousel_container">
            //   <div className="home_carousel">
            //     <h1>Explore {usernameObj.username} Card Game</h1>
            //     <p>{message}</p>
            //   </div>
            //   <button className="btn carousel_trynow_button">TRY NOW</button>
            // </div>
            <Link to={message.link} target={"_blank"} key={index}>

              <img src={message.imgSrc} alt="" className="desktop_banner_img" />
              <img src={message.mobileimgSrc} alt="" className="mobile_portrait_banner_img" />
            </Link>
          );
        })}
      </Slider>
    </div>
  );
};

export default withTranslation()(Carousel);
