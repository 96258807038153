import React, { useEffect, useState } from 'react'
import { makeAjax, respStatus, showMessage, url } from "../../../helpers/global_helper";
import { formatPricewithdecimal } from '../../NumberFormat'
import DownArrow from "../../../assets/images/icons/down_arrow.svg";
import UpArrow from "../../../assets/images/icons/up_arrow.svg"
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { setTotalNoResult } from '../../../redux/actions';

const EventBonus = (props) => {
  const dispatch = useDispatch()
  const [eventBonusData, setEventBonusData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(false);
  const pageNumber = useSelector((state) => state.paginationData.pageNumber)
  const perPage = useSelector((state) => state.paginationData.dataInOnePage)

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };
  const sortedData = () => {
    if (sortBy === "date") {
      return eventBonusData.slice().sort((a, b) => {
        const aValue = new Date(a[sortBy]).getTime();
        const bValue = new Date(b[sortBy]).getTime();
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      });
    } else if (sortBy === "turnover" || sortBy === "bonusamount") {
      return eventBonusData.slice().sort((a, b) => {
        const aValue = parseFloat(a[sortBy]);
        const bValue = parseFloat(b[sortBy]);
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      });
    } else if (sortBy) {
      return eventBonusData.slice().sort((a, b) => {
        const aValue = a[sortBy].toLowerCase();
        const bValue = b[sortBy].toLowerCase();
        return sortOrder === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      });
    }
    return eventBonusData;
  };


  // api for Event Bonus list
  const fetchEventBonusList = () => {
    setLoading(true)

    let reqData = {
      // type: "bonus_event",
      page: pageNumber,
      length: perPage,
    };

    makeAjax(url.PLAYER_API.listEventBonus, reqData, url.PLAYER_MS_EXT).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        if (response.code !== "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      setEventBonusData(response.data.data);
      dispatch(setTotalNoResult(response.data.total));
      console.log("123456", response.data.total);
    });
    setLoading(false)
  };

  useEffect(() => {
    fetchEventBonusList();
  }, [
    perPage,
    sortBy, sortOrder,
    pageNumber
  ]);
  return (
    <div className="tab_content" >
      <div className="table_main">
        <table className='dataTableMenuBar' border="0">
          <thead>
            <tr>
              <th onClick={() => handleSort("date")}>
                {props.t("date")}
                <span className="sorting_arrow">
                  <img src={sortBy === "date" && sortOrder === "asc" ? UpArrow : DownArrow} alt="" />
                </span>
              </th>
              <th onClick={() => handleSort("event")}>
                {props.t("event")}
                <span className="sorting_arrow">
                  <img src={sortBy === "event" && sortOrder === "asc" ? UpArrow : DownArrow} alt="" />
                </span>

              </th>
              <th onClick={() => handleSort("game")}>
                {props.t("game")}
                <span className="sorting_arrow">
                  <img src={sortBy === "game" && sortOrder === "asc" ? UpArrow : DownArrow} alt="" />
                </span>
              </th>
              <th onClick={() => handleSort("turnover")}>
                {props.t("turnover")}
                <span className="sorting_arrow">
                  <img src={sortBy === "turnover" && sortOrder === "asc" ? UpArrow : DownArrow} alt="" />
                </span>
              </th>
              <th>{props.t("category")}</th>
              <th onClick={() => handleSort("bonusamount")}>
                {props.t("bonus_amount")}
                <span className="sorting_arrow">
                  <img src={sortBy === "bonusamount" && sortOrder === "asc" ? UpArrow : DownArrow} alt="" />
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              (sortedData().length === 0 ? (
                <tr className="table_data_empty_row ">
                  <td colSpan="6">{props.t("data_not_found")}</td>
                </tr>
              ) :
                (
                  sortedData().map((item, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "first_bg" : "second_bg"}
                    >
                      <td>{item.date}</td>
                      <td>{item.event}</td>
                      <td>{item.game}</td>
                      <td>{formatPricewithdecimal(item.turnover)}</td>
                      <td>{item.category}</td>
                      <td>{formatPricewithdecimal(item.bonusamount)}</td>
                    </tr>
                  ))
                ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withTranslation()(EventBonus);
