import Domino0 from "../../../assets/images/card_images/Domino/DMM0.png";
import Domino1 from "../../../assets/images/card_images/Domino/DMM1.png";
import Domino2 from "../../../assets/images/card_images/Domino/DMM2.png";
import Domino3 from "../../../assets/images/card_images/Domino/DMM3.png";
import Domino4 from "../../../assets/images/card_images/Domino/DMM4.png";
import Domino5 from "../../../assets/images/card_images/Domino/DMM5.png";
import Domino6 from "../../../assets/images/card_images/Domino/DMM6.png";
import Domino7 from "../../../assets/images/card_images/Domino/DMM7.png";
import Domino8 from "../../../assets/images/card_images/Domino/DMM8.png";
import Domino9 from "../../../assets/images/card_images/Domino/DMM9.png";
import Domino10 from "../../../assets/images/card_images/Domino/DMM10.png";
import Domino11 from "../../../assets/images/card_images/Domino/DMM11.png";
import Domino12 from "../../../assets/images/card_images/Domino/DMM12.png";
import Domino13 from "../../../assets/images/card_images/Domino/DMM13.png";
import Domino14 from "../../../assets/images/card_images/Domino/DMM14.png";
import Domino15 from "../../../assets/images/card_images/Domino/DMM15.png";
import Domino16 from "../../../assets/images/card_images/Domino/DMM16.png";
import Domino17 from "../../../assets/images/card_images/Domino/DMM17.png";
import Domino18 from "../../../assets/images/card_images/Domino/DMM18.png";
import Domino19 from "../../../assets/images/card_images/Domino/DMM19.png";
import Domino20 from "../../../assets/images/card_images/Domino/DMM20.png";
import Domino21 from "../../../assets/images/card_images/Domino/DMM21.png";
import Domino22 from "../../../assets/images/card_images/Domino/DMM22.png";
import Domino23 from "../../../assets/images/card_images/Domino/DMM23.png";
import Domino24 from "../../../assets/images/card_images/Domino/DMM24.png";
import Domino25 from "../../../assets/images/card_images/Domino/DMM25.png";
import Domino26 from "../../../assets/images/card_images/Domino/DMM26.png";
import Domino27 from "../../../assets/images/card_images/Domino/DMM27.png";
import Domino28 from "../../../assets/images/card_images/Domino/DMM28.png";

export const dominoDiceImage = {
  0: Domino0,
  1: Domino1,
  2: Domino2,
  3: Domino3,
  4: Domino4,
  5: Domino5,
  6: Domino6,
  7: Domino7,
  8: Domino8,
  9: Domino9,
  10: Domino10,
  11: Domino11,
  12: Domino12,
  13: Domino13,
  14: Domino14,
  15: Domino15,
  16: Domino16,
  17: Domino17,
  18: Domino18,
  19: Domino19,
  20: Domino20,
  21: Domino21,
  22: Domino22,
  23: Domino23,
  24: Domino24,
  25: Domino25,
  26: Domino26,
  27: Domino27,
  28: Domino28,
};
