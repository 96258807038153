import Maintenance from "../../assets/images/maintenance.svg";
const UnderMaintenance = () => {

    return (
        <div className="maintenance_warpper">
            <div className="maintenance-main">
                <img src={Maintenance} alt="" />
                <p>Under Maintenance</p>
            </div>
        </div>
    )
}
export default UnderMaintenance;