import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import { formatPricewithdecimal } from "../../NumberFormat";
import DownArrow from "../../../assets/images/icons/down_arrow.svg";
import UpArrow from "../../../assets/images/icons/up_arrow.svg";
import { withTranslation } from "react-i18next";
import ReferralBonusDatePopup from "../InformationPopups/ReferralBonusDatePopup";
import { setTotalNoResult } from "../../../redux/actions";

function ReferralBonus(props) {
  const dispatch = useDispatch();
  const [referralBonusData, setReferralBonusData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [referralbonusdatepopup, setReferralBonusPopup] = useState(false);
  const [referralbonusDateId, setReferralBonusDateId] = useState(null);
  const [loading, setLoading] = useState(false);
  const pageNumber = useSelector((state) => state.paginationData.pageNumber);
  const perPage = useSelector((state) => state.paginationData.dataInOnePage);

  const openReferralBonusDatePopup = (turnoverDateId) => {
    console.log("click");
    setReferralBonusDateId(turnoverDateId);
    setReferralBonusPopup(true);
  };

  const closeReferralBonusDatePopup = () => {
    setReferralBonusPopup(false);
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortedData = () => {
    let sortedArray = [];

    if (sortBy === "created_date") {
      sortedArray = referralBonusData.slice().sort((a, b) => {
        const aValue = new Date(a[sortBy]).getTime();
        const bValue = new Date(b[sortBy]).getTime();
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      });
    } else if (sortBy === "turnover") {
      sortedArray = referralBonusData.slice().sort((a, b) => {
        const aValue = parseFloat(a[sortBy].replace(/,/g, ""));
        const bValue = parseFloat(b[sortBy].replace(/,/g, ""));
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      });
    } else if (sortBy) {
      sortedArray = referralBonusData.slice().sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      });
    } else {
      sortedArray = referralBonusData.slice();
    }
    return sortedArray;
  };

  // api for Referral Bonus list
  const fetchReferralBonus = () => {
    setLoading(true);
    let reqData = {
      page: pageNumber,
      length: perPage,
    };

    makeAjax(url.PLAYER_API.listReferralBonus, reqData, url.PLAYER_MS_EXT).then(
      (response) => {
        if (response.status !== respStatus["SUCCESS"]) {
          if (response.code !== "validation_error") {
            showMessage(response);
            return;
          }
          showMessage(response);
          return;
        }
        setReferralBonusData(response.data.data);
        dispatch(setTotalNoResult(response.data.total));
        console.log("123456", response.data.total);
      }
    );
    setLoading(false);
  };

  useEffect(() => {
    fetchReferralBonus();
    const container = document.querySelector(".table_container");
    if (container) {
      container.scrollTop = 0;
    }
  }, [perPage, sortBy, sortOrder, pageNumber]);

  return (
    <div className="tab_content">
      <div className="table_main">
        <table className="dataTableMenuBar" border="0">
          <thead>
            <tr>
              <th onClick={() => handleSort("created_date")}>
                {props.t("register_date")}
                <span className="sorting_arrow">
                  <img
                    src={
                      sortBy === "created_date" && sortOrder === "asc"
                        ? UpArrow
                        : DownArrow
                    }
                    alt=""
                  />
                </span>
              </th>
              <th onClick={() => handleSort("turnover")}>
                {props.t("turnover")}
                <span className="sorting_arrow">
                  <img
                    src={
                      sortBy === "turnover" && sortOrder === "asc"
                        ? UpArrow
                        : DownArrow
                    }
                    alt=""
                  />
                </span>
              </th>
              <th onClick={() => handleSort("commission")}>
                {props.t("commision")}
                <span className="sorting_arrow">
                  <img
                    src={
                      sortBy === "commission" && sortOrder === "asc"
                        ? UpArrow
                        : DownArrow
                    }
                    alt=""
                  />
                </span>
              </th>
            </tr>
          </thead>

          <tbody>
            {!loading && sortedData().length === 0 ? (
              <tr className="table_data_empty_row">
                <td colSpan="3">{props.t("data_not_found")}</td>
              </tr>
            ) : (
              sortedData().map((item, index) => {
                return (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "first_bg" : "second_bg"}
                  >
                    <td
                      onClick={() =>
                        openReferralBonusDatePopup(item.created_date)
                      }
                    >
                      <span className="link">{item.created_date}</span>
                    </td>

                    <td>{formatPricewithdecimal(item.turnover)}</td>
                    <td>{parseFloat(item.commission).toFixed(2)}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {referralbonusdatepopup && (
        <ReferralBonusDatePopup
          onClose={closeReferralBonusDatePopup}
          referralbonusDateId={referralbonusDateId}
        />
      )}
    </div>
  );
}

export default withTranslation()(ReferralBonus);
