import React from "react";
import { withTranslation } from "react-i18next";
import { formatPricewithdecimal } from "../../NumberFormat";

const DetailsMoPopup = (props) => {
  const {
    historyId,
    datetime,
    game_name,
    period,
    status,
    credit,
    debit,
    coin,
    balance,
  } = props;

  const renderStatus = (status) => {
    if (status === 21) {
      return props.t("bet");
    } else if (status === 22) {
      return props.t("win");
    } else if (status === 23) {
      return props.t("lose");
    } else if (status === 25) {
      return props.t("draw");
    } else if (status === 30 || status === "Jackpot") {
      return props.t("win_regular_jackpot");
    } else if (status === 24) {
      return "Buy Gift";
    } else if (status === 26) {
      return "Topup";
    } else if (status === 27) {
      return "Buy In";
    } else if (status === 28) {
      return "Buy Out";
    } else if (status === 29) {
      return "Buy Mega Jackpot";
    } else if (status === 36) {
      return "Cash Out Coins";
    } else if (props.act === 7) {
      return props.t("expired_coin");
    } else {
      return status; 
    }
  };
  return (
    <div className="poupup_detail_table">
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("history")} ID</div>
        <div className="poupup_detail_table_desc">{historyId}</div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("date")}</div>
        <div className="poupup_detail_table_desc">{datetime}</div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("game")}</div>
        <div className="poupup_detail_table_desc">{game_name}</div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("period")}</div>
        <div className="poupup_detail_table_desc">{period}</div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("status")}</div>
        <div className="poupup_detail_table_desc">{renderStatus(status)}</div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("credit")}</div>
        <div className="poupup_detail_table_desc">
          {formatPricewithdecimal(credit)}
        </div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("debit")}</div>
        <div className="poupup_detail_table_desc">
          {formatPricewithdecimal(debit)}
        </div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("coin")}</div>
        <div className="poupup_detail_table_desc">
          {formatPricewithdecimal(coin)}
        </div>
      </div>
      <div className="poupup_detail_table_row">
        <div className="poupup_detail_table_title">{props.t("balance")}</div>
        <div className="poupup_detail_table_desc">
          {formatPricewithdecimal(balance)}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(DetailsMoPopup);
