import { useEffect, useState } from "react";
import Tabs from "./Tabs";
import InfoIcon from "../../../src/assets/images/icons/navigation/Info.svg";
import ReferralBonus from "../Information/SubComponents/ReferralBonus";
import TurnoverInfo from "../Information/SubComponents/TurnoverInfo";
import EventBonus from "../Information/SubComponents/EventBonus";
import TransactionMo from "./SubComponent/TransactionMo";
import MemberList from "../Information/SubComponents/MemberList";
import Pagination from "../Pagination/Pagination";
import { dataEntryPerPage, setPaginationData } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

const InformationMoDesign = () => {
  const [tabId, setTabId] = useState("transaction");
  const TabList = [
    {
      title: "transaction",
      id: "transaction",
    },
    {
      title: "member list",
      id: "member-list",
    },
    {
      title: "Referral Bonus",
      id: "referral-bonus",
    },
    {
      title: "Turnover Info",
      id: "turnover-info",
    },
    {
      title: "Event Bonus",
      id: "event-bonus",
    },
  ];
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState("Transaction");
  const [perPage, setPerPage] = useState(10);
  const [resetPage, setResetPage] = useState(false);

  const handlePageNo = () => {
    setResetPage(true);
    window.scrollTo(0, 0)
  };

  const handleResetPageNo = () => {
    setResetPage(false);
  };


  const handlePerRowsChange = (e) => {
    const perPageValue = e.target.value;
    setPerPage(perPageValue);
    setResetPage(true);
  };


  useEffect(() => {
    setPerPage(10);
  }, [selectedTab]);

  dispatch(dataEntryPerPage(perPage))


  useEffect(() => {
    setSelectedTab(tabId);
    dispatch(setPaginationData(tabId))
  }, [tabId])



  return (
    <>
      <div className="inner_content information_page">
        <div className="table content_wrapper">
          <h2 className="heading_1"><img src={InfoIcon} alt="" />Information</h2>
          <div className="header_bottom_border"></div>
          {/* table start  */}
          <div className="inner_container">
            <div className="info-header">
              <Tabs list={TabList} tabId={tabId} setTabId={setTabId} />
              <div>
                <select className="ui search dropdown" onChange={handlePerRowsChange} value={perPage}>
                  <option value={10}>{10}</option>
                  <option value={25}>{25}</option>
                  <option value={50}>{50}</option>
                  <option value={100}>{100}</option>
                </select>
              </div>
              <div className="pagination_tab">
              </div>
            </div>
            {tabId === "transaction" ? <TransactionMo handlePageNo={handlePageNo} /> : ""}
            {tabId === "member-list" ? <MemberList /> : ""}
            {tabId === "referral-bonus" && <ReferralBonus />}
            {tabId === "turnover-info" ? <TurnoverInfo /> : ""}
            {tabId === "event-bonus" ? <EventBonus /> : ""}
          </div>
          {/* table end  */}
          <Pagination resetPage={resetPage} handleResetPageNo={handleResetPageNo} />
        </div>
      </div>
    </>
  );
};
export default InformationMoDesign;



