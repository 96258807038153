import React, { useState, useEffect } from "react";
import Tabs from "../Information/Tabs";
import TexasPokerWinner from "./SubComponents/TexasPokerWinner";
import PokerClassicWinner from "./SubComponents/PokerClassicWinner";
import DominoQQWinner from "./SubComponents/DominoQQWinner";
import CemeFighterWinner from "./SubComponents/CemeFighterWinner";
import BolaTangkasWinner from "./SubComponents/BolaTangakasWinner";
import CemeWinner from "./SubComponents/CemeWinner";
import TexasPokerInfo from "./SubComponents/TexasPokerInfo";
import PokerClassic from "./SubComponents/PokerClassic";
import DominoQQInfo from "./SubComponents/DominoQQInfo";
import CemeInfo from "./SubComponents/CemeInfo";
import CemeFighterInfo from "./SubComponents/CemeFighterInfo";
import { makeAjax, respStatus, showMessage, url } from "../../helpers/global_helper";
import { withTranslation } from "react-i18next";
import JackpotIcon from "../../assets/images/HeadIcon/jackpot.svg";
import { useSelector } from "react-redux";

function Jackpot(props) {
  const [tabId, setTabId] = useState("texas-poker");
  const [jackpotContent, setJackpotContent] = useState(true);
  const [availableGameIds, setAvailableGameIds] = useState([]);
  const [loadingTabs, setLoadingTabs] = useState(true);  
  const device = useSelector((state) => state.CommonConfig.device_type);

  const TabList = [
    { title: "Texas Poker", id: "texas-poker", tab_id: 101, gameCode: "txh"},
    { title: "Domino QQ", id: "domino-QQ", tab_id: 105, gameCode: "dmb" },
    { title: "Ceme", id: "ceme", tab_id: 103, gameCode: "dmc" },
    { title: "Ceme Fighter", id: "ceme-fighter", tab_id: 104 , gameCode: "dbc"},
    { title: "Texas Poker Classic", id: "texas-poker-classic", tab_id: 151 , gameCode: "txp"},
    { title: "Bola Tangkas", id: "bola-tangkas", tab_id: 108 , gameCode: "mmb"},
  ];

  const fetchGameList = () => {
    makeAjax(url.CARD_GAME_API.getGameList).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        if (response.code !== "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      const gameListData = response.data.gamelist.map((game) => ({
        gameid: game.game_id,
      }));
      setAvailableGameIds(gameListData.map((game) => game.gameid));
      setLoadingTabs(false); 
    });
  };

  useEffect(() => {
    fetchGameList();
  }, []);

  let filteredTabs = TabList.filter((tab) => availableGameIds.includes(tab.tab_id));

  const handleJackpotSystem = () => setJackpotContent(true);

  const handleJackpotWinner = () => setJackpotContent(false);

  useEffect(() => {
    if (tabId === "bola-tangkas") {
      setJackpotContent(false);
    }
  }, [tabId]);


  const currentTab = TabList.find((tab) => tab.id === tabId);
  const gameCode = currentTab ? currentTab.gameCode : '';

  return (
    <>
      <div className="inner_content jackpot_page">
        <div className="table content_wrapper">
          {device === "Mobile" || device === "Tablet" ? (
            <>
              <h2 className="heading_1">
                <img src={JackpotIcon} alt="" />
                Jackpot
              </h2>
              <div className="header_bottom_border"></div>
            </>
          ) : null}

          <div className="inner_container">
            {!loadingTabs && <Tabs list={filteredTabs} tabId={tabId} setTabId={setTabId} />}

            {!loadingTabs && (
              <div className="jackpot_colunm_row">
                <div className="jackpot_syswin_row">
                  {tabId !== "bola-tangkas" && (
                    <>
                      <div
                        onClick={handleJackpotSystem}
                        className={`jackpot_syswin_tab ${jackpotContent ? "active " : ""}`}
                      >
                        <p>{props.t('jackpot_system')}</p>
                      </div>
                      <div
                        onClick={handleJackpotWinner}
                        className={`jackpot_syswin_tab ${!jackpotContent ? "active " : ""}`}
                      >
                        <p>{props.t('jackpot_winner')}</p>
                      </div>
                    </>
                  )}
                  {tabId === "bola-tangkas" && (
                    <div
                      onClick={handleJackpotWinner}
                      className="jackpot_syswin_tab active"
                    >
                      <p>{props.t('jackpot_winner')}</p>
                    </div>
                  )}
                </div>
              </div>
            )}

            {!loadingTabs && (
              <div className="jackpot_content">
                {jackpotContent && (
                  <div className="jackpot_system_txtdiv">
                    {tabId === "texas-poker" && <TexasPokerInfo/>}
                    {tabId === "domino-QQ" && <DominoQQInfo/>}
                    {tabId === "ceme" && <CemeInfo/>}
                    {tabId === "ceme-fighter" && <CemeFighterInfo/>}
                    {tabId === "bola-tangkas" && <BolaTangkasWinner/>}
                    {tabId === "texas-poker-classic" && <PokerClassic/>}
                  </div>
                )}
                {!jackpotContent && (
                  <div className="jackpot_system_txtdiv">
                    {tabId === "texas-poker" && <TexasPokerWinner gameCode={gameCode} />}
                    {tabId === "domino-QQ" && <DominoQQWinner gameCode={gameCode} />}
                    {tabId === "ceme" && <CemeWinner gameCode={gameCode} />}
                    {tabId === "ceme-fighter" && <CemeFighterWinner gameCode={gameCode} />}
                    {tabId === "texas-poker-classic" && <PokerClassicWinner gameCode={gameCode} />}
                    {tabId === "bola-tangkas" && <BolaTangkasWinner gameCode={gameCode} />}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(Jackpot);
