import {
  SET_COMMON_CONFIG
} from "./actionTypes"

const initialState = {
  device_type: null,
}

const CommonConfig = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMON_CONFIG:
      state[action.payload.key] = action.payload.value;
      break
  }
  return state
}

export default CommonConfig
