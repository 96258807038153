import React, { useEffect, useState } from "react";
import RefreshBtn from "../../../src/assets/images/icons/refresh_btn.png";
import { formatPricewithdecimal } from "../NumberFormat";
import InfiniteScroll from "react-infinite-scroll-component";
import { makeAjax, respStatus, showMessage, url } from "../../helpers/global_helper";
import RewardIcon from "../../assets/images/HeadIcon/reward.svg";
import { withTranslation } from "react-i18next";
import UpArrow from "../../assets/images/icons/up_arrow.svg";
import DownArrow from "../../assets/images/icons/down_arrow.svg";
import { useSelector } from "react-redux";
function CoinRewards(props) {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isItems, setIsitems] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState({});
  const [sortBy, setSortBy] = useState("game_name");
  const [sortOrder, setSortOrder] = useState("asc");
  const device = useSelector(state => state.CommonConfig.device_type);

  const fetchRewardData = () => {
    let reqData = {
      page: page,
    };
    makeAjax(url.PLAYER_API.getCoinReward, reqData, url.PLAYER_MS_EXT).then(
      (response) => {
        if (response.status !== respStatus["SUCCESS"]) {
          if (response.code !== "validation_error") {
            showMessage("server error No data found", "error", "error");
          } else {
            showMessage("server error No data found", "error", "error");
          }
          return;
        }
        const newData = response.data.reward.data;
        setItems((prevItems) => [...prevItems, ...newData]);
        setPage((prevPage) => prevPage + 1);
        setHasMore(newData.length > 0);
      }
    );
  };

  useEffect(() => {
    fetchRewardData();
  }, []);

  const handleRefreshBalance = () => {
    setIsitems(true);
    fetchRewardData();
    setTimeout(() => {
      setIsitems(false);
    }, 2000);
  };

  const cashOutApiHandle = (id, game_id, coin, index) => {
    setDisabledButtons((prevState) => ({ ...prevState, [index]: true }));
    setItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[index] = { ...newItems[index], coin: "0.00" };
      return newItems;
    });
    let reqData = {
      id: id,
      gameId: game_id,
    };
    makeAjax(url.PLAYER_API.updateReward, reqData, url.PLAYER_MS_EXT).then(
      (response) => {
        if (response.status !== respStatus["SUCCESS"]) {
          setDisabledButtons((prevState) => ({ ...prevState, [index]: false }));
          setItems((prevItems) => {
            const newItems = [...prevItems];
            newItems[index] = { ...newItems[index], coin: coin };
            return newItems;
          });
          if (response.code !== "validation_error") {
            showMessage(props.t("internal_err"));
          } else {
            showMessage(props.t("trouble_balance"));
          }
          return;
        }
        handleRefreshBalance();
        showMessage(
          `Cash Out Coin to the main balance of ${formatPricewithdecimal(coin)} was successful`,
          "success",
          props.t('message')
        );

      }
    );
  };

  // const handleSort = (sortByParam) => {
  //   if (sortByParam === sortBy) {
  //     setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  //   } else {
  //     setSortBy(sortByParam);
  //     setSortOrder("asc");
  //   }
  // };

  // const sortedItems = React.useMemo(() => {
  //   const sorted = [...items].sort((a, b) => {
  //     if (sortBy === "game_name") {
  //       return sortOrder === "asc"
  //         ? a.game_name.localeCompare(b.game_name)
  //         : b.game_name.localeCompare(a.game_name);
  //     }
  //     if (sortBy === "coin") {
  //       return sortOrder === "asc"
  //         ? parseFloat(a.coin) - parseFloat(b.coin)
  //         : parseFloat(b.coin) - parseFloat(a.coin);
  //     }
  //     if (sortBy === "expired") {
  //       return sortOrder === "asc"
  //         ? a.expired.localeCompare(b.expired)
  //         : b.expired.localeCompare(a.expired);
  //     }
  //     if (sortBy === "period") {
  //       return sortOrder === "asc"
  //         ? parseFloat(a.period) - parseFloat(b.period)
  //         : parseFloat(b.period) - parseFloat(a.period);
  //     }
  //     if (sortBy === "turnover") {
  //       return sortOrder === "asc"
  //         ? parseFloat(a.turnover) - parseFloat(b.turnover)
  //         : parseFloat(b.turnover) - parseFloat(a.turnover);
  //     }
  //     return 0;
  //   });
  //   return sorted;
  // }, [items, sortBy, sortOrder]);

  return (
    <>
      <div className="inner_content">
        <div className="table content_wrapper">
          {
            device === "Mobile" || device === "Tablet" ? <><h2 className="heading_1"><img src={RewardIcon} alt="" />{props.t("reward")}</h2><div className="header_bottom_border"></div></> : ""
          }

          {/* table start  */}
          <div className="inner_container">
            <div className="table_main">
              <InfiniteScroll
                dataLength={items.length}
                next={() => fetchRewardData()}
                hasMore={hasMore}
                endMessage={<p>{props.t("no_more_items_to_load")}</p>}
                scrollThreshold={0.7}
                height={"60vh"}
                style={{
                  overflow: "inherit",
                  height: "inherit",
                  maxHeight: "70vh",
                  color: "#fff",
                  fontSize: "12px",
                }}
              >
                <table className="dataTableMenuBar">
                  <thead>
                    <tr>
                      <th>
                        {props.t("game")}{" "}
                        {/* <span className="sorting_arrow">
                          <img
                            src={
                              sortBy === "game_name" && sortOrder === "asc"
                                ? UpArrow
                                : DownArrow
                            }
                            alt=""
                            onClick={() => handleSort("game_name")}
                          />
                        </span> */}
                      </th>
                      <th>
                        {props.t("coin")}{" "}
                        {/* <span className="sorting_arrow">
                          <img
                            src={
                              sortBy === "coin" && sortOrder === "asc"
                                ? UpArrow
                                : DownArrow
                            }
                            alt=""
                            onClick={() => handleSort("coin")}
                          />
                        </span> */}
                      </th>
                      <th>
                        {props.t("expired")}{" "}
                        {/* <span className="sorting_arrow">
                          <img
                            src={
                              sortBy === "expired" && sortOrder === "asc"
                                ? UpArrow
                                : DownArrow
                            }
                            alt=""
                            onClick={() => handleSort("expired")}
                          />
                        </span> */}
                      </th>
                      <th>
                        {props.t("remaining_period")}
                        {/* <span className="sorting_arrow">
                          <img
                            src={
                              sortBy === "period" && sortOrder === "asc"
                                ? UpArrow
                                : DownArrow
                            }
                            alt=""
                            onClick={() => handleSort("period")}
                          />
                        </span> */}
                      </th>
                      <th>
                        {props.t("remaining_turnover")}
                        {/* <span className="sorting_arrow">
                          <img
                            src={
                              sortBy === "turnover" && sortOrder === "asc"
                                ? UpArrow
                                : DownArrow
                            }
                            alt=""
                            onClick={() => handleSort("turnover")}
                          />
                        </span> */}
                      </th>
                      <th>
                        <img
                          src={RefreshBtn}
                          alt=""
                          className={`coin_refresh ${isItems ? "coinRotation" : ""
                            }`}
                          onClick={handleRefreshBalance}
                        />
                        <span className="sorting_arrow"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((reward, index) => {
                      const isPeriodZero = parseFloat(reward.period) === 0;
                      const isTurnOverZero =
                        parseFloat(reward.turnover) === 0;
                      const isCoinZero = parseFloat(reward.coin) !== 0;
                      const isButtonEnabled =
                        isPeriodZero && isTurnOverZero && isCoinZero;

                      return (
                        <tr key={index}>
                          <td>{reward.game_name}</td>
                          <td>{formatPricewithdecimal(reward.coin)}</td>
                          <td>{reward.expired}</td>
                          <td>{isPeriodZero ? "Achieved" : reward.period}</td>
                          <td>
                            {isTurnOverZero
                              ? "Achieved"
                              : formatPricewithdecimal(reward.turnover)}
                          </td>
                          <td className="reward">
                            <button
                              disabled={
                                !isButtonEnabled || disabledButtons[index]
                              }
                              onClick={() =>
                                cashOutApiHandle(
                                  reward.id,
                                  reward.game_id,
                                  reward.coin,
                                  index
                                )
                              }
                              className="cash-out-btn"
                            >
                              CASH OUT
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(CoinRewards);
