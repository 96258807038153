import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import CoinReward from "../../../../../src/assets/images/HeadIcon/reward.svg";
import JackpotIcon from "../../../../../src/assets/images/HeadIcon/jackpot.svg";
import InfoIcon from "../../../../../src/assets/images/HeadIcon/Information.svg";
import NotifyIcon from "../../../../../src/assets/images/HeadIcon/notify.svg";
import EnglishFlag from "../../../../../src/assets/images/Lang/english_flag.svg";
import IndonesiaFlag from "../../../../../src/assets/images/Lang/indonesia_flag.svg";
import LogoutIcon from "../../../../../src/assets/images/HeadIcon/exit.svg";
import NotificationPage from "../../../NotificationPage/NotificationPage";
import Information from "../../../Information/Information";
import Jackpot from "../../../Jackpot/Jackpot";
import Modal from "../../../Modal/Modal";
import CoinRewards from "../../../CoinRewards/CoinRewards";
import Language from "../../../Language/Language";
import { useSelector } from "react-redux";
import { makeAjax, respStatus, showMessage, url } from "../../../../helpers/global_helper";
import { TOKEN_KEY } from "../../../../helpers/url_helper";
import { withTranslation } from "react-i18next";

function WebNavigation(props) {
  const user_balance = useSelector((state) => state.UserBalance.user_balance);
  const [popup, setPopup] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showLangPopup, setShowLangPopup] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [title, setTitle] = useState(null);
  const [modalSrc, setModalSrc] = useState(null);
  const [active, setActive] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("I18N_LANGUAGE") || 'en');
  const [langIcon, setLangIcon] = useState(language === 'en' ? EnglishFlag : IndonesiaFlag);

  useEffect(() => {
    setLangIcon(language === 'en' ? EnglishFlag : IndonesiaFlag);
  }, [language]);

  const handleIconClick = (popupComponent, title, src) => {
    setPopup(popupComponent);
    setShowPopup(true);
    setTitle(title);
    setModalSrc(src);
  };

  const handleLanguageClick = () => {
    setShowLangPopup(true);
  };

  const closeModal = () => {
    setShowPopup(false);
    setTitle(null);
    setModalSrc(null);
    setActive(false);
    setShowLogoutPopup(false);
  };

  const closeLangModal = () => {
    setShowLangPopup(false);
    setShowLogoutPopup(false);
  };

  // logout
  const handleLogout = () => {
    setShowLogoutPopup(true);
    setTimeout(() => {
      makeAjax(url.PLAYER_API.logout, null, url.PLAYER_MS_EXT).then(
        (response) => {
          if (response.status !== respStatus["SUCCESS"]) {
            showMessage(response);
            localStorage.removeItem(TOKEN_KEY);
            setTimeout(() => {
              window.close();
            }, 2000);
            return;
          }
          localStorage.removeItem(TOKEN_KEY);
          setTimeout(function () {
            window.close();
          }, 1000);
        }
      );
    }, 3000);
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const icons = [
    { src: langIcon, onClick: handleLanguageClick, title: props.t('language') },
    { src: CoinReward, popup: <CoinRewards />, title: props.t('reward') },
    { src: InfoIcon, popup: <Information />, title: props.t('information') },
    { src: NotifyIcon, popup: <NotificationPage />, title: props.t('notification') },
    { src: JackpotIcon, popup: <Jackpot />, title: props.t('jackpot') },
    { src: LogoutIcon, onClick: handleLogout, title: props.t('logout') },
  ];

  const handleIconButtonClick = (icon, title) => {
    if (icon.onClick) {
      icon.onClick();
    } else {
      handleIconClick(icon.popup, title, icon.src);
    }
  };

  return (
    <>
      <div className="web_navigation" data-role="navigation">
        <ul>
          {icons.map((icon, index) => (
            <li key={index} className={title === icon.title ? "active" : null}>
              <div
                onClick={() => handleIconButtonClick(icon, icon.title)}
                className="nav_item"
              >
                <div className="nav_icon_holder">
                  <img src={icon.src} alt="" />
                  {icon.src === NotifyIcon && (
                    <span className="nav_notify_count">
                      {user_balance.countNotif}
                    </span>
                  )}
                </div>
                <p className="nav_title">{icon.title}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="nav_popup">
        {showPopup && (
          <Modal
            modalHeader={true}
            modalTitle={title}
            modalSrc={modalSrc}
            onCloseModal={closeModal}
          >
            {popup}
          </Modal>
        )}
      </div>
      {showLangPopup && <Language onClose={closeLangModal} onLanguageChange={handleLanguageChange} />}
      {showLogoutPopup && (
        <div className="logout_modal">
          <Modal
            modalHeader={true}
            modalTitle={props.t("message")}
            modalSrc={modalSrc}
            onCloseModal={closeModal}
          >
            <div className="nick_success_message">
              <p> {props.t("you_ve_been_logged_out_successfully")}</p>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}

export default withTranslation()(WebNavigation);
