import Heart_A from "../../../assets/images/card_images/HEART/A_HEART.png";
import Heart_2 from "../../../assets/images/card_images/HEART/2_HEART.png";
import Heart_3 from "../../../assets/images/card_images/HEART/3_HEART.png";
import Heart_4 from "../../../assets/images/card_images/HEART/4_HEART.png";
import Heart_5 from "../../../assets/images/card_images/HEART/5_HEART.png";
import Heart_6 from "../../../assets/images/card_images/HEART/6_HEART.png";
import Heart_7 from "../../../assets/images/card_images/HEART/7_HEART.png";
import Heart_8 from "../../../assets/images/card_images/HEART/8_HEART.png";
import Heart_9 from "../../../assets/images/card_images/HEART/9_HEART.png";
import Heart_10 from "../../../assets/images/card_images/HEART/10_HEART.png";
import Heart_J from "../../../assets/images/card_images/HEART/J_HEART.png";
import Heart_Q from "../../../assets/images/card_images/HEART/Q_HEART.png";
import Heart_K from "../../../assets/images/card_images/HEART/K_HEART.png";

import Spade_A from "../../../assets/images/card_images/SPADE/A_SPADE.png";
import Spade_2 from "../../../assets/images/card_images/SPADE/2_SPADE.png";
import Spade_3 from "../../../assets/images/card_images/SPADE/3_SPADE.png";
import Spade_4 from "../../../assets/images/card_images/SPADE/4_SPADE.png";
import Spade_5 from "../../../assets/images/card_images/SPADE/5_SPADE.png";
import Spade_6 from "../../../assets/images/card_images/SPADE/6_SPADE.png";
import Spade_7 from "../../../assets/images/card_images/SPADE/7_SPADE.png";
import Spade_8 from "../../../assets/images/card_images/SPADE/8_SPADE.png";
import Spade_9 from "../../../assets/images/card_images/SPADE/9_SPADE.png";
import Spade_10 from "../../../assets/images/card_images/SPADE/10_SPADE.png";
import Spade_J from "../../../assets/images/card_images/SPADE/J_SPADE.png";
import Spade_Q from "../../../assets/images/card_images/SPADE/Q_SPADE.png";
import Spade_K from "../../../assets/images/card_images/SPADE/K_SPADE.png";

import Diamond_A from "../../../assets/images/card_images/DIAMOND/A_DIAMOND.png";
import Diamond_2 from "../../../assets/images/card_images/DIAMOND/2_DIAMOND.png";
import Diamond_3 from "../../../assets/images/card_images/DIAMOND/3_DIAMOND.png";
import Diamond_4 from "../../../assets/images/card_images/DIAMOND/4_DIAMOND.png";
import Diamond_5 from "../../../assets/images/card_images/DIAMOND/5_DIAMOND.png";
import Diamond_6 from "../../../assets/images/card_images/DIAMOND/6_DIAMOND.png";
import Diamond_7 from "../../../assets/images/card_images/DIAMOND/7_DIAMOND.png";
import Diamond_8 from "../../../assets/images/card_images/DIAMOND/8_DIAMOND.png";
import Diamond_9 from "../../../assets/images/card_images/DIAMOND/9_DIAMOND.png";
import Diamond_10 from "../../../assets/images/card_images/DIAMOND/10_DIAMOND.png";
import Diamond_J from "../../../assets/images/card_images/DIAMOND/J_DIAMOND.png";
import Diamond_Q from "../../../assets/images/card_images/DIAMOND/Q_DIAMOND.png";
import Diamond_K from "../../../assets/images/card_images/DIAMOND/K_DIAMOND.png";

import Club_A from "../../../assets/images/card_images/CLUB/A_CLUB.png";
import Club_2 from "../../../assets/images/card_images/CLUB/2_CLUB.png";
import Club_3 from "../../../assets/images/card_images/CLUB/3_CLUB.png";
import Club_4 from "../../../assets/images/card_images/CLUB/4_CLUB.png";
import Club_5 from "../../../assets/images/card_images/CLUB/5_CLUB.png";
import Club_6 from "../../../assets/images/card_images/CLUB/6_CLUB.png";
import Club_7 from "../../../assets/images/card_images/CLUB/7_CLUB.png";
import Club_8 from "../../../assets/images/card_images/CLUB/8_CLUB.png";
import Club_9 from "../../../assets/images/card_images/CLUB/9_CLUB.png";
import Club_10 from "../../../assets/images/card_images/CLUB/10_CLUB.png";
import Club_J from "../../../assets/images/card_images/CLUB/J_CLUB.png";
import Club_Q from "../../../assets/images/card_images/CLUB/Q_CLUB.png";
import Club_K from "../../../assets/images/card_images/CLUB/K_CLUB.png";

import Joker_Hitam from "../../../assets/images/card_images/resultpopup_cards/Joker-Hitam.png"
import Joker_Merah from "../../../assets/images/card_images/resultpopup_cards/Joker-Merah.png"

export const resultPopupImages = {
    '0': Club_A,
    '1': Club_2,
    '2': Club_3,
    '3': Club_4,
    '4': Club_5,
    '5': Club_6,
    '6': Club_7,
    '7': Club_8,
    '8': Club_9,
    '9': Club_10,
    '10': Club_J,
    '11': Club_Q,
    '12': Club_K,
    '13': Diamond_A,
    '14': Diamond_2,
    '15': Diamond_3,
    '16': Diamond_4,
    '17': Diamond_5,
    '18': Diamond_6,
    '19': Diamond_7,
    '20': Diamond_8,
    '21': Diamond_9,
    '22': Diamond_10,
    '23': Diamond_J,
    '24': Diamond_Q,
    '25': Diamond_K,
    '26': Heart_A,
    '27': Heart_2,
    '28': Heart_3,
    '29': Heart_4,
    '30': Heart_5,
    '31': Heart_6,
    '32': Heart_7,
    '33': Heart_8,
    '34': Heart_9,
    '35': Heart_10,
    '36': Heart_J,
    '37': Heart_Q,
    '38': Heart_K,
    '39': Spade_A,
    '40': Spade_2,
    '41': Spade_3,
    '42': Spade_4,
    '43': Spade_5,
    '44': Spade_6,
    '45': Spade_7,
    '46': Spade_8,
    '47': Spade_9,
    '48': Spade_10,
    '49': Spade_J,
    '50': Spade_Q,
    '51': Spade_K,
    '52': Joker_Hitam,
    '53': Joker_Merah
};


