import React from 'react';
import fastImg from "../../../assets/images/card_images/table_icon/fast.svg";
import fasteerImg from "../../../assets/images/card_images/table_icon/Faster.svg";
import BegginerImg from "../../../assets/images/card_images/table_icon/Beginner.svg";
import ProImg from "../../../assets/images/card_images/table_icon/Pro.svg";
import { withTranslation } from 'react-i18next';
const TableIconDisplay = (props) => {
  const { type, t } = props;
  return (
    <div className='TableIconcontainer'>
      {type === 3 && (
        <>
          <img src={fastImg} alt={t("fast")} />
          {t("fast")}
        </>
      )}
      {type === 4 && (
        <>
          <img src={fasteerImg} alt={t("faster")} />
          {t("faster")}
        </>
      )}
      {(type === 5 || type === 7) && (
        <>
          <img src={BegginerImg} alt={t("beginner")} />
          {t("beginner")}
        </>
      )}
      {(type === 6 || type === 10) && (
        <>
          <img src={ProImg} alt="Pro" />
          Pro
        </>
      )}
      {type === 8 && (
        <>
          <img src={BegginerImg} alt={t("beginner")} />
          {t("beginner")}
          <img src={fastImg} alt={t("fast")} />
          {t("fast")}
        </>
      )}
      {type === 9 && (
        <>
          <img src={BegginerImg} alt={t("beginner")} />
          {t("beginner")}
          <img src={fasteerImg} alt={t("faster")} />
          {t("faster")}
        </>
      )}
      {type === 11 && (
        <>
          <img src={ProImg} alt="Pro" />
          Pro
          <img src={fastImg} alt={t("fast")} />
          {t("fast")}
        </>
      )}
      {type === 12 && (
        <>
          <img src={ProImg} alt="Pro" />
          Pro
          <img src={fasteerImg} alt={t("faster")} />
          {t("faster")}
        </>
      )}
    </div>
  );
};
export default withTranslation()(TableIconDisplay);
