import React from 'react'

const FooterRightMenu = () => {
    return (
        <>

            <div className="right-footInfo-bar">
                <div className='right-footer-icon-div' >
                    <p>JACKPOT : 1,02,33,000 IDR</p>
                    <div className='right_footer_icon'>
                        <img src="cardGamePackage/jackpot.svg" alt="JackpOt" />
                    </div>
                </div>
            </div>

        </>
    )
}

export default FooterRightMenu