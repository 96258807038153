import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { formatPricewithdecimal } from "../../NumberFormat";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import JackpotBg from "../../../assets/images/home_main/Jackpot_bg.svg";

const Jackpot = ({ selectedGameName, gameCode }) => {
    const [jackpotAmount, setJackpotAmount] = useState(null);
    const [initialJackpotAmount, setInitialJackpotAmount] = useState(null);
    const [isloading, setIsLoading] = useState();
    // API to fetch the total jackpot amount 
    const fetchTotalJackpotAmount = (gameCode) => {
      const reqData = { gameCode };     
      makeAjax(url.CARD_GAME_API.totalJackpot, reqData)
        .then((response) => {
          if (response.status !== respStatus["SUCCESS"]) {
            showMessage(response);
            return;
          }  
          const amount = parseFloat(response.data);
          setInitialJackpotAmount(amount); 
          setIsLoading(true)
        });                
    };  
  
    const jackpotGameNames = ["Texas Poker", "Texas Poker Classic", "Domino QQ"];
  
    useEffect(() => {
      if (jackpotGameNames.includes(selectedGameName)) {
        fetchTotalJackpotAmount(gameCode);  
      }
    }, [selectedGameName, gameCode]);
  
    useEffect(() => {
      let interval;  
      if (initialJackpotAmount !== null) {
        setJackpotAmount(initialJackpotAmount); 
  
        interval = setInterval(() => {
          setJackpotAmount(prevAmount => prevAmount + 1);
        }, 1000);
      }  
      return () => clearInterval(interval); 
    }, [initialJackpotAmount]); 
  
    return (
      <>
        {jackpotGameNames.includes(selectedGameName) && (
          <div className="jackpot_div">
            <div className="jackpot_bg_img">
              <div className="jackpot-content">
                <p>Jackpot</p>
                <div className="jackpot-amnt">
                {jackpotAmount !== null ? formatPricewithdecimal(jackpotAmount) : <span className="loader"></span>}
                </div>
              </div>
              <img src={JackpotBg} alt="Jackpot Background" />
            </div>
          </div>
        )}
      </>
    );
  };

export default withTranslation()(Jackpot);
