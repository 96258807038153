import "./App.css";
import { useState, useEffect } from "react";
import NavigationComponent from "./component/NavigationComponent/NavigationComponent";
import HKBlogo from "./assets/images/Logo/HKB-logo.svg";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LaunchLobby from "./component/LaunchLobby/LaunchLobby";
import LoaderImg from "./assets/images/icons/ajax-loader.svg";
import Nickname from "./component/NickName/NickName";
import { withTranslation } from "react-i18next";
import { setCommonConfig } from "./redux/actions";
import { useDispatch, useSelector } from "react-redux";
import LogoutMesage from "./component/Common/LogoutMesage";
import CloseIcon from "./assets/images/modal/close.svg";
import wifiIcon from "./assets/images/icons/wifi_icon.svg";
import Modal from "./component/Modal/Modal";
import UnderMaintenance from "./component/Common/UnderMaintenance";
import { makeAjax, url } from "./helpers/global_helper";
import SessionExpireMessage from "./component/Common/SessionExpireMessage";
import Bonus from "./component/Bonus/Bonus";

function App(props) {
  const dispatch = useDispatch();
  const [partnerKey, setPartnerKey] = useState(localStorage.getItem("partner"));
  const [background, setBackground] = useState("");
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [deviceType, setDeviceType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [width, setWidth] = useState(0);
  const [loadingComp, setLoadingComp] = useState(true);
  const [loader, setLoader] = useState("");
  const clientLoader = loader || localStorage.getItem("loader");
  const loginToken = localStorage.getItem("token");
  const [showBonus, setShowBonus] = useState(false); 

  useEffect(() => {
    if (
      !isLoading &&
      window.location.pathname !== "/logout" &&
      window.location.pathname !== "/sessionexpired" &&
      window.location.pathname !== "/nickname"
    ) {
      const timer = setTimeout(() => {
        setShowBonus(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isLoading, window.location.pathname]);

  const closeOnlinePopup = () => {
    setIsOnline(false);
  };
  const device = useSelector((state) => state.CommonConfig.device_type);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [device]);

  useEffect(() => {
    if (loginToken) {
        setTimeout(() => {
          setIsLoading(false)
        }, 1500);
    }
  }, [loginToken])

  useEffect(() => {
    if (window.location.pathname == "/logout") {
      setIsLoading(false)
    }
    if (window.location.pathname == "/nickname") {
      setIsLoading(false)
    }
  }, [window.location.pathname, loginToken])

  const helpRunfunction = () => {
    makeAjax(url.PLAYER_API.checkSession, {}, url.PLAYER_MS_EXT)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      helpRunfunction()
    }, 120000);
    return () => clearInterval(interval);
  }, [])

  useEffect(() => {
    let interval;
    if (isLoading) {
      setWidth(0); // Reset width to 0 when loading starts
      interval = setInterval(() => {
        setWidth((prevWidth) => {
          const newWidth = prevWidth + 1;
          if (newWidth >= 100) {
            clearInterval(interval);
          }
          return newWidth;
        });
      }, 55);
    } else {
      setTimeout(() => {
        setWidth(100);
      }, 100);
    }
    return () => clearInterval(interval);
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && width === 100) {
      setTimeout(() => {
        setLoadingComp(false);
      }, 500);
    }
  }, [width, isLoading]);

  useEffect(() => {
    const handlePartnerNameChange = () => {
      setPartnerKey(localStorage.getItem("partner"));
    };
    window.addEventListener("partnerNameChange", handlePartnerNameChange);
    return () => {
      window.removeEventListener("partnerNameChange", handlePartnerNameChange);
    };
  }, []);

  useEffect(() => {
    setBackground(
      window.location.pathname === "/" ? "mainPageBgImage" : "backColorSet"
    );
    const handleLocationChange = () => {
      setBackground(
        window.location.pathname === "/" ? "mainPageBgImage" : "backColorSet"
      );
    };
    window.addEventListener("popstate", handleLocationChange);
    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, [background]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);
  const handleUrlChange = () => {
    setBackground(
      window.location.pathname === "/" ? "mainPageBgImage" : "backColorSet"
    );
    const handleLocationChange = () => {
      setBackground(
        window.location.pathname === "/" ? "mainPageBgImage" : "backColorSet"
      );
    };
    window.addEventListener("popstate", handleLocationChange);
    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  };
  const onClose = () => {
    document.getElementById("commonToaster").style.display = "none";
  };
  useEffect(() => {
    function isDevice() {
      const userAgent = navigator.userAgent;
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        );
      const isTablet = /iPad|Android/.test(userAgent) && !isMobile;
      if (isMobile) {
        return "Mobile";
      } else if (isTablet) {
        return "Tablet";
      } else {
        return "Desktop";
      }
    }
    window.isDevice = isDevice;
    const device = window.isDevice();
    setDeviceType(device);
    dispatch(setCommonConfig({ key: "device_type", value: device }));
    return () => {
      delete window.isDevice;
    };
  }, [dispatch]);
  return (
    <>
      <div id="commonToaster" className="error-popup">
        <div className="error-popup-content">
          <span className="close" onClick={onClose}>
            &times;
          </span>
          <p id="commonToasterHeading" className="message"></p>
          <div className="header_bottom_border"></div>
          <p id="commonToasterBody" className="error-message"></p>
        </div>
      </div>
      {!loadingComp && (
        <div className="ajax_loader_container hideMe" id="ajax-loader">
          <div className="ajax_loader">
            <img src={LoaderImg} alt="" />
          </div>
        </div>
      )}

      {loadingComp && window.location.pathname !== "/sessionexpired" && (
        <div className="loader-background">
          <div id="ajax-loader" className="custom-loader">
            <div
              className="custom-loader-line"
              style={{ width: `${width}%` }}
            ></div>
            <div className="lds-ripple"></div>
          </div>
        </div>
      )}

      {loadingComp && window.location.pathname !== "/sessionexpired" && (
        <div className="loader-background">
          <div className="loader_img">
            {clientLoader && (
              <img src={clientLoader} alt="Loader" width="100%" height="100%" />
            )}
          </div>

          <div id="ajax-loader" className="custom-loader">
            <div
              className="custom-loader-line"
              style={{ width: `${width}%` }}
            ></div>
            {clientLoader !== "" && <div className="lds-ripple"></div>}
          </div>
          <div className="loader-footer">
            Copyright © 2016 {localStorage.getItem("partner")?.toUpperCase()}.
            All Rights Reserved.
          </div>
        </div>
      )}
      {!isOnline && (
        <>
          <div className="wifi_popup">
            <Modal closeButton={true}>
              <button onClick={closeOnlinePopup} className="btnclose">
                <img src={CloseIcon} alt="closeIcon" />
              </button>

              <div className="conn_lost_inner">
                <img src={wifiIcon} alt="wifi" />
                <h2></h2>
                <div className="notification_body">
                  <h3>Connection Lost</h3>
                  <p>Your connection appears to be off-line.</p>
                </div>
              </div>
            </Modal>
          </div>
        </>
      )}
      {showBonus && <Bonus />}

      <div
        className={`${background} allDimentionSet wrapper `}
        onClick={handleUrlChange}
        id={
          deviceType === "Mobile" || deviceType === "Tablet"
            ? "mobile"
            : "desktop"
        }
      >
        <Router>
          <Routes>
            <Route
              path="/api/lobby/:token/:operatorid/:username"
              element={<LaunchLobby setLoader={setLoader} />}
            />
            <Route path="/sessionexpired" element={<SessionExpireMessage />} />
            <Route path="*" element={<NavigationComponent />} />
            {/* <Route path="/app/:error_type" element={<ServerError />} /> */}
            <Route path="/nickname" element={<Nickname />} />
            <Route path="/maintenance" element={<UnderMaintenance />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}
export default withTranslation()(App);
