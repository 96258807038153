import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import CloseIcon from "../../assets/images/modal/close.svg";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../helpers/global_helper";
import NotifyIcon from "../../assets/images/icons/navigation/notification.svg";
const NotificationResultPopup = (props) => {
  const { onClose, notificationId, notificationSubject } = props;
  const [notificationData, setNotificationData] = useState([]);

  // api for Notification Result Popup
  const fetchNotificationResultData = () => {
    let reqData = {
      notification_id: notificationId,
    };
    makeAjax(url.PLAYER_API.getNotification, reqData, url.PLAYER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage("server connection lost", "error", "not getting data");
            return;
          }
          showMessage("server connection lost ", "error", "not geeting data");
          return;
        }
        setNotificationData(response.data);
      }
    );
  };

  useEffect(() => {
    if (notificationId) {
      fetchNotificationResultData();
    }
  }, [notificationId]);

  return (
    <>
      <div className="modal_popup_overlay"></div>
      <div className="modal_popup">
        <div className="modal_popup_header">
          <h2 className="heading_1">  {props.t("information")}</h2>
          <div className="header_bottom_border"></div>
          <button className="modal_popup_close" onClick={onClose}>
            <img src={CloseIcon} alt="" />
          </button>
          <div className="tab_content">
            <div className="table_main">
              <h3 className="notifica_heading">{notificationSubject}</h3>
              <p className="notifica_txt" dangerouslySetInnerHTML={{ __html: notificationData }}></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(NotificationResultPopup);
