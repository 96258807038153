import React, {useEffect} from "react";
import { withTranslation } from "react-i18next";

const LogoutMesage = (props) => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'radial-gradient(50% 50% at 50% 50%, #b01010 0%, rgba(87, 19, 19, 1) 100%)',
    color: '#fff'
  };

  const messageStyle = {
    textAlign: 'center'
  };

  useEffect(()=>{
    
    const timer = setTimeout(()=>{
      window.close();
    },2000)

    // Cleanup the timer when the component is unmounted
    return () => clearTimeout(timer)
  },[]);
  return (
    <div style={containerStyle}>
      <div style={messageStyle}>
        <h1>{props.t('you_have_logged_out')}</h1>
        <h4>{props.t('please_close_this_tab_or_window')}</h4>
      </div>
    </div>
  );
};

export default withTranslation()(LogoutMesage);
