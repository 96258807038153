import React from "react";
import Modal from "../Modal/Modal";
// import InnerPopup from "../Modal/InnerPopup";

const ConfirmNicknamePopup = ({ nickname, onClose, onUpdate }) => {
  return (
    <div className="nickname_modal">
      <Modal onCloseModal={onClose}>
        <div className="nickname_poupcontent">
          <p>
            Apakah anda yakin menggunakan nickname <br />
            "{nickname}" ?
          </p>
          <div className="nickname_poupcontent_btns">
            <button className="btn btn_red" onClick={onClose} >
              Batal
            </button>
            <button className="btn btn_yellow" onClick={onUpdate}>
              Konfirmasi
            </button>

          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmNicknamePopup;
