export const getJackpotTypes = (gamecode) => {
    console.log("getJackpotTypes",gamecode);
    const typeJpPoker = {
        6: "Full House",
        7: "Four of a Kind",
        8: "Straight Flush",
        9: "Royal Flush",
        10: "Royal Ruby",
        11: "Royal Diamond",
      };
    
      const typeJpDomino = {
        9: "Pure Big",
        10: "Pure Small",
        11: "Twin Card",
        12: "Six Deity",
      };


      if (gamecode === "dmc" || gamecode === "dmb" || gamecode === "dbc"){
        return typeJpDomino;
      }else {
        return typeJpPoker;
      }
}