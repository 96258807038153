import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
// import LoaderImg from "../../../../../../../src/assets/images/icons/ajax-loader.svg"
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import { formatPricewithdecimal } from "../../NumberFormat";
import CloseIcon from "../../../assets/images/modal/close.svg";

const BetPopup = (props) => {
  const { status, bettype, date, gameId, limitId, period, transId, onClose } =
    props;
  const [transectionDetailData, setTransectionDetailsData] = useState([]);
  const [gameCode, setGameCode] = useState("");
  const [totalBuyers, setTotalBuyers] = useState(0);

  // api for Transection History Detail
  const featchTransectionHistoryDetail = () => {
    let reqData = {
      act: status,
      bettype: bettype,
      date: date,
      game_id: gameId,
      limitid: limitId,
      period: parseInt(period),
      trans_id: transId,
    };
    console.log("Bet popup reqData", reqData)
    makeAjax(url.PLAYER_API.getTransaction, reqData, url.PLAYER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response);
            return;
          }
          showMessage(response);
          return;
        }

        setTransectionDetailsData(response.data.result);
        setGameCode(response.data.game.game_code);
        // Calculate total buyers
        const total = response.data.result.reduce(
          (accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.bayar);
          },
          0
        );
        setTotalBuyers(total);
      }
    );
  };

  useEffect(() => {
    featchTransectionHistoryDetail();
  }, []);
  return (
    <div className="modal_popup">
      <div className="modal_popup_header">
        {props.t("info_turnover")} &nbsp;
        {props.t("win")} / {props.t("lose")}
        <div className="header_bottom_border"></div>
        <button className="modal_popup_close" onClick={onClose}>
          <img src={CloseIcon} alt="" />
        </button>
      </div>
      <div className="tab_content">
        <div className="table_main">
          <table className="dataTableMenuBar" border="0">
            <thead>
              <tr>
                <th>{props.t("period")}</th>
                <th>{props.t("bet")}</th>
                <th>{props.t("amount")}</th>
                <th>{props.t("discount")} %</th>
                <th>Kei %</th>
                <th>{props.t("prize")}</th>
                <th>{props.t("total_amount")}</th>
                <th>{props.t("description")}</th>
              </tr>
            </thead>
            <tbody>
              {transectionDetailData.length === 0 ? (
                <tr className="table_data_empty_row">
                  <td colSpan="8">{props.t("data_not_found")}</td>
                </tr>
              ) : (
                transectionDetailData.map((value, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "first_bg" : "second_bg"}
                  >
                    <td>{`${parseInt(period)}-${gameCode}`}</td>
                    <td>{value.bet}</td>
                    <td>{formatPricewithdecimal(value.amount)}</td>
                    <td>{value.disc}</td>
                    <td>-</td>
                    <td>{formatPricewithdecimal(value.prize)}</td>
                    <td>{formatPricewithdecimal(value.bayar)}</td>
                    <td>{value.info}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <div className="total_price_div">
            {props.t("total")}: {totalBuyers.toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(BetPopup);
