import { SET_DATA_IN_ONEPAGE, SET_PAGE_NUMBER, SET_PAGINATION, SET_TOTAL_NO_RESULT } from "./actionTypes"

const initialState = {
  pagination: "Transaction",
  pageNumber: 1,
  dataInOnePage: 10,
  totalNumberOfResult: 0
}

const paginationData = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload
      };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload
      };
    case SET_DATA_IN_ONEPAGE:
      return {
        ...state,
        dataInOnePage: action.payload
      };
    case SET_TOTAL_NO_RESULT:
      return {
        ...state,
        totalNumberOfResult: action.payload
      };
    default:
      return state;
  }
};

export default paginationData
