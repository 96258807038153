import React, { useState, useEffect } from "react";
import { VscChevronDown } from "react-icons/vsc";
import { VscChevronUp } from "react-icons/vsc";
import refreshBtn from "../../../assets/images/icons/refresh_btn.png";
import { withTranslation } from "react-i18next";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import { formatPricewithdecimal } from "../../NumberFormat";
import ResultPopup from "./ResultPopup";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import PlayerPopup from "./PlayerPopup";
import TableIconDisplay from "./TableIconDisplay ";
import CloseIcon from "../../../assets/images/modal/close_iconpopup.svg";
import Jackpot from "./Jackpot"
import Modal from "../../Modal/Modal";
const TableDataInfo = (props) => {
  const { onClose } = props;
  // props for device Mobile and Desktop
  const device = useSelector((state) => state.CommonConfig.device_type);
  const location = useLocation();
  let selectedGameName, gameCode, gameListImages, gameId;

  if (device === "Mobile") {
    const locationProps = location.state || {};
    ({ selectedGameName, gameCode, gameListImages, gameId } = locationProps);
    // console.log('=>Device: Mobile');
    // console.log('=>Location State:', location.state);
  } else {
    ({ selectedGameName, gameCode, gameListImages, gameId } = props);

    // console.log('=>Device: Desktop');
    // console.log('=>Props:', props);
  }
  const [tableData, setTableData] = useState([]);
  const [tableDataDiff, setTableDataDiff] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [specialtabData, setSpecialTabData] = useState([]);
  const [gameName, setGameName] = useState(
    selectedGameName || "Select Game"
  );
  const [roomName, setRoomName] = useState("All");
  const [isItems, setIsitems] = useState(false);
  const [hideFull, setHideFull] = useState(false);
  const [hideEmpty, setHideEmpty] = useState(false);
  const [tangkasHideFull, setTangkasHideFull] = useState(false);
  const [originalTableData, setOriginalTableData] = useState([]);
  const [resultPoupOpen, setResultPopupOpen] = useState(false);
  const [playerPopupOpen, setPlayerPopupOpen] = useState(false);
  const [selectedTableId, setSelectedTableId] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [isTrue, setIsTrue] = useState(true);
  const [roomFullPopup, setRoomFullPopup] = useState(false);
  const [showRoomPopup, setShowRoomPopup] = useState(false);
  const [gameConfirmMessage, setGameConfirmMessage] = useState("");
  const [newTableId, setNewTableId] = useState(null);
  const [currentTableId, setCurrentTableId] = useState(null);

  const handlePopupOpen = (tableId) => {
    setSelectedTableId(tableId);
    setResultPopupOpen(true);
  };

  const handleClose = () => {
    setResultPopupOpen(false);
  };

  const handlePlayerPopupOpen = (tableId) => {
    setSelectedTableId(tableId);
    setPlayerPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPlayerPopupOpen(false);
  };

  const columnMappings = {
    "Texas Poker": ["tablename", "tableminmax", "tableblind", "tableplayer", "Jackpot"],
    "Poker Dealer": ["tablename", "tablemin", "tablestakes", "tableplayer", "tablemindealer"],
    "Game Ceme": ["tablename", "tablemin", "tablestakes", "tableplayer", "tablemindealer"],
    "Ceme Fighter": ["tablename", "tablemin", "tablestakes", "tableplayer", "tablemindealer"],
    "Domino QQ": ["tablename", "tableminmax", "tablestakes", "tableplayer", "Jackpot"],
    "Black Jack": ["tablename", "tablemin", "tablestakes", "tableplayer", "tablemindealer"],
    "Big Two": ["tablename", "tablemin", "tablestakes", "tableplayer"],
    "Super 10": ["tablename", "tableminmax", "tableblind", "tableplayer"],
    "Omaha Poker": ["tablename", "tableminmax", "tableblind", "tableplayer"],
    "Three Kings": ["tablename", "tablemin", "tablestakes", "tableplayer", "tablemindealer"],
    "Joker Dealer": ["tablename", "tablemin", "tablestakes", "tableplayer", "tablemindealer"],
    "Adu-Q": ["tablename", "tablemin", "tablestakes", "tableplayer"],
    "Bull Bull Dice": ["tablename", "tableminmax", "tablestakes", "tableplayer"],
    "Baccarat": ["tablename", "tablemin", "tablestakes", "tableplayer", "tablemindealer"],
    "Bandar QQ": ["tablename", "tablemin", "tablestakes", "tableplayer", "tablemindealer"],
    "Gaple": ["tablename", "tablemin", "tablestakes", "tableplayer"],
    "Dadu Poker": ["tablename", "tablemin", "tablestakes", "tableplayer"],
    "Rokak Uruk": ["tablename", "tablemin", "tablestakes", "tableplayer", "tablemindealer"],
    "Jisong": ["tablename", "tableminmax", "tablestakes", "tableplayer"],
    "Texas Poker Classic": ["tablename", "tableminmax", "tableblind", "tableplayer", "Jackpot"],
  };
  const specialColumnsMapping = {
    "Bola Tangkas": ["No.", "user", "Royal", "5K", "STRF", "4K", "tablehistory"],
    "Tangkas Classic": ["No.", "user", "Royal", "5K", "STRF", "4K", "tablehistory"],
  };
  // Translate column names
  const translateColumnNames = (columns, translate) => {
    return columns.map(column => translate(column));
  };

  const columns = translateColumnNames(columnMappings[gameName] || [], props.t);
  const specialColumns = translateColumnNames(specialColumnsMapping[gameName] || [], props.t);
  // console.log("#columns", columns);
  // console.log("#tableDataDiff", tableDataDiff);
  // console.log("#specialtabData", specialtabData);
  // console.log("#gameName", gameName);
  const roomNameMapping = {
    All: "all",
    [props.t("smallest")]: 0,
    [props.t("small")]: 1,
    [props.t("medium")]: 2,
    [props.t("large")]: 3,
    [props.t("vip")]: 4,
  };

  // API for fetching room Tab data
  const fetchTabData = () => {
    let reqData = {
      gameCode: gameCode,
      gameId: gameId,
      subgame_togel: 3,
    };
    makeAjax(url.CARD_GAME_API.subGameRoomList, reqData).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      const roomNames = response.data.ind
        ? Object.values(response.data.ind).map((roomName) => {
          // Apply translation based on the roomName value
          switch (roomName.toLowerCase()) {
            case 'smallest':
              return props.t('smallest');
            case 'small':
              return props.t('small');
            case 'medium':
              return props.t('medium');
            case 'large':
              return props.t('large');
            case 'vip':
              return props.t('vip');
            default:
              return roomName;
          }
        })
        : [];

      setTabData(roomNames);
      console.log("roomNames", roomNames)

      // Special room names for Bola Tangkas or Tangkas Classic
      const specialRoomNames = response.data.dataroom
        ? Object.values(response.data.dataroom)
        : [];
      setSpecialTabData(specialRoomNames);
    });
  };
  useEffect(() => {
    applyFilters();
  }, [hideFull, hideEmpty, tangkasHideFull, originalTableData]);

  const applyFilters = () => {
    let filteredData = originalTableData;
    if (hideFull) {
      filteredData = filteredData.filter(
        (item) => item.total_player !== item.max_player
      );
    }
    if (tangkasHideFull) {
      filteredData = filteredData.filter(
        (item) => item.nickname == null
      );
    }
    if (hideEmpty) {
      filteredData = filteredData.filter((item) => item.total_player !== 0);
    }
    setTableData(filteredData);
  };
  useEffect(() => {
    fetchTabData();
  }, []);

  // API for fetching table data
  const fetchTableData = (roomName) => {
    let reqData = {
      gameCode: gameCode,
      roomName:
        roomNameMapping[roomName] !== undefined
          ? roomNameMapping[roomName]
          : roomName,
    };
    makeAjax(url.CARD_GAME_API.chooseRoom, reqData).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      const data = response.data.datatable || [];
      setTableData(data);
      setTableDataDiff(data);
      setOriginalTableData(data);
    });
  };

  const sortTableData = (data, key, direction) => {
    return [...data].sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];
      if (key === 'jackpot' || key === 'min_buyin' || key === 'sblind' || key === "total_player" || key === "max_buyin" || key === "bonus_royal" || key === "bonus_5k" || key === "bonus_str" || key === "bonus_4k" || key === "min_dealer" || key === "stake") {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);

        if (aValue < bValue) return direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return direction === 'asc' ? 1 : -1;
        return 0;
      } else if (key === 'name') {
        aValue = aValue ? aValue.toString().toLowerCase() : '';
        bValue = bValue ? bValue.toString().toLowerCase() : '';

        if (aValue < bValue) return direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return direction === 'asc' ? 1 : -1;
        return 0;
      }
    });
  };

  const handleFilter = (column) => {
    const newDirection = sortConfig.direction === 'asc' ? 'desc' : 'asc';
    if (column === props.t("tablename")) {
      setSortConfig({ key: "name", direction: newDirection });
    } else if (column === "Jackpot") {
      setSortConfig({ key: "jackpot", direction: newDirection });
    } else if (column === props.t("tablestakes")) {
      // setSortConfig({ key: "stake", direction: newDirection }); 
      const key = selectedGameName === "Big Two" ? "stake" : "sblind";
      setSortConfig({ key, direction: newDirection });
    } else if (column === props.t("tableblind")) {
      const key = selectedGameName === "Texas Poker" || "Omaha Poker" ? "sblind" : "stake";
      setSortConfig({ key, direction: newDirection });
    } else if (column === props.t("tableplayer")) {
      setSortConfig({ key: "total_player", direction: newDirection });
    } else if (column === props.t("tableminmax")) {
      setSortConfig({ key: "max_buyin", direction: newDirection });
    } else if (column === props.t("tablemin")) {
      setSortConfig({ key: "min_buyin", direction: newDirection });
    } else if (column === props.t("tablemindealer")) {
      setSortConfig({ key: "min_dealer", direction: newDirection });
    }
    setIsTrue(!isTrue);
  };

  const handleFilterSpecial = (column) => {
    const newDirection = sortConfig.direction === 'asc' ? 'desc' : 'asc';
    if (column === "Royal") {
      setSortConfig({ key: "bonus_royal", direction: newDirection });
    } else if (column === "5K") {
      setSortConfig({ key: "bonus_5k", direction: newDirection });
    } else if (column === "STRF") {
      setSortConfig({ key: "bonus_str", direction: newDirection });
    } else if (column === "4K") {
      setSortConfig({ key: "bonus_4k", direction: newDirection });
    }
    setIsTrue(!isTrue)
  };

  const handleFullRoom = () => {
    setRoomFullPopup(true);
  };

  const sortedTableData = sortTableData(
    tableData,
    sortConfig.key,
    sortConfig.direction
  );

  useEffect(() => {
    fetchTableData(roomName);
  }, [gameCode, roomName]);

  const handleRefreshClick = () => {
    setIsitems(true);
    fetchTableData(roomName);
    setTimeout(() => {
      setIsitems(false);
    }, 2000);
  };

  const launchRoom = async (gameCode, tableId) => {
    let reqData = {
      gameCode: gameCode,
      tableId: tableId,
    };

    try {
      // Make the AJAX request to get the game link
      makeAjax(url.CARD_GAME_API.getGameLink, reqData).then((response) => {
        // if (response.data.error != '') {
        //   showMessage(response.data.error);
        //   return;
        // }

        // if (response.code == 'game_success') {
        //   let message = props.t(response.data.hold_table_text).replace("{value}", response.data.hold_table_value);
        //   setNewTableId(response.data.table_id);
        //   setCurrentTableId(response.hold_table_id);
        //   setGameConfirmMessage(message);
        //   setShowRoomPopup(true);
        //   return;
        // }

        if (response.status !== respStatus["SUCCESS"]) {
          if (response.status == 'ERROR') {
            showMessage(props.t(response.code));
            return;
          }
          showMessage(response);
          return;
        }

        // Extract necessary information from the response
        const token = response.data.token;
        const operatorid = response.data.operatorid;
        const username = response.data.username;
        const one_time_token = response.data.one_time_token;

        let url = response.data.link;
        const gameUrl = "/api/cardGame";
        let query_param = {
          gametypex: gameCode,
          tableid: tableId,
          token: token,
          operatorid: operatorid,
          username: username,
          one_time_token: one_time_token,
        };

        let param_string = Object.keys(query_param)
          .map(function (key) {
            return (
              encodeURIComponent(key) +
              "=" +
              encodeURIComponent(query_param[key])
            );
          })
          .join("&");
        url = url + gameUrl + "?" + param_string;
        // window.open(url, "_blank");
        window.open(url, /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent) ? "_self" : "_blank");
      });
    } catch (error) {
      console.error('Error during the API call:', error);
      showMessage('An unexpected error occurred. Please try again later.');
    }
  };

  const renderTableRows = () => {
    if (sortedTableData.length === 0) {
      return (
        <tr>
          <td colSpan={columns.length + 1} style={{ textAlign: "center" }}>
            {props.t("data_not_found")}
          </td>
        </tr>
      );
    }
    return sortedTableData.map((item, index) => (
      <tr key={index}>
        {columns.map((column, colIndex) => (
          <td
            key={colIndex}
            className={column === props.t("tableplayer") ? "Player_blue_text" : ""}
          >
            {
              // Texas Poker
              selectedGameName === "Texas Poker" && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : selectedGameName === "Texas Poker" &&
                column === props.t("tableminmax") ? (
                <>
                  {formatPricewithdecimal(item.min_buyin)} /{" "}
                  {formatPricewithdecimal(item.max_buyin)}
                </>
              ) : selectedGameName === "Texas Poker" && column === props.t("tableblind") ? (
                <>
                  {formatPricewithdecimal(item.sblind)} /{" "}
                  {formatPricewithdecimal(item.bblind)}
                </>
              ) : selectedGameName === "Texas Poker" && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : selectedGameName === "Texas Poker" && column === "Jackpot" ? (
                formatPricewithdecimal(item.jackpot)
              ) : //  Poker Dealer
                selectedGameName === "Poker Dealer" && column === props.t("tablename") ? (
                  <>
                    {item.name}
                    <TableIconDisplay type={item.type} />
                  </>
                ) : selectedGameName === "Poker Dealer" &&
                  column === props.t("tablemin") ? (
                  formatPricewithdecimal(item.min_buyin)
                ) : selectedGameName === "Poker Dealer" && column === props.t("tablestakes") ? (
                  `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                ) : selectedGameName === "Poker Dealer" && column === props.t("tableplayer") ? (
                  // `${item.total_player} / ${item.max_player}`
                  <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                    {`${item.total_player} / ${item.max_player}`}
                  </span>
                ) : selectedGameName === "Poker Dealer" &&
                  column === props.t("tablemindealer") ? (
                  formatPricewithdecimal(item.min_dealer)
                ) : //  Game Ceme
                  selectedGameName === "Game Ceme" && column === props.t("tablename") ? (
                    <>
                      {item.name}
                      <TableIconDisplay type={item.type} />
                    </>
                  ) : selectedGameName === "Game Ceme" && column === props.t("tablemin") ? (
                    formatPricewithdecimal(item.min_buyin)
                  ) : selectedGameName === "Game Ceme" && column === props.t("tablestakes") ? (
                    `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`

                  ) : selectedGameName === "Game Ceme" && column === props.t("tableplayer") ? (
                    // `${item.total_player} / ${item.max_player}`
                    <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                      {`${item.total_player} / ${item.max_player}`}
                    </span>
                  ) : selectedGameName === "Game Ceme" &&
                    column === props.t("tablemindealer") ? (
                    formatPricewithdecimal(item.min_dealer)
                  ) : //  Ceme Fighter
                    selectedGameName === "Ceme Fighter" && column === props.t("tablename") ? (
                      <>
                        {item.name}
                        <TableIconDisplay type={item.type} />
                      </>
                    ) : selectedGameName === "Ceme Fighter" &&
                      column === props.t("tablemin") ? (
                      formatPricewithdecimal(item.min_buyin)
                    ) : selectedGameName === "Ceme Fighter" && column === props.t("tablestakes") ? (
                      `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                    ) : selectedGameName === "Ceme Fighter" && column === props.t("tableplayer") ? (
                      // `${item.total_player} / ${item.max_player}`
                      <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                        {`${item.total_player} / ${item.max_player}`}
                      </span>
                    ) : selectedGameName === "Ceme Fighter" &&
                      column === props.t("tablemindealer") ? (
                      formatPricewithdecimal(item.min_dealer)
                    ) : //  Domino QQ
                      selectedGameName === "Domino QQ" && column === props.t("tablename") ? (
                        <>
                          {item.name}
                          <TableIconDisplay type={item.type} />
                        </>
                      ) : selectedGameName === "Domino QQ" &&
                        column === props.t("tableminmax") ? (
                        `${formatPricewithdecimal(item.min_buyin)} / ${formatPricewithdecimal(item.max_buyin)}`
                      ) : selectedGameName === "Domino QQ" && column === props.t("tablestakes") ? (
                        `${formatPricewithdecimal(item.sblind)} / ${formatPricewithdecimal(item.bblind)}`
                      ) : selectedGameName === "Domino QQ" && column === props.t("tableplayer") ? (
                        // `${item.total_player} / ${item.max_player}`
                        <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                          {`${item.total_player} / ${item.max_player}`}
                        </span>
                      ) : selectedGameName === "Domino QQ" && column === "Jackpot" ? (
                        formatPricewithdecimal(item.jackpot)
                      ) : //  Black Jack
                        selectedGameName === "Black Jack" && column === props.t("tablename") ? (
                          <>
                            {item.name}
                            <TableIconDisplay type={item.type} />
                          </>
                        ) : selectedGameName === "Black Jack" && column === props.t("tablemin") ? (
                          formatPricewithdecimal(item.min_buyin)
                        ) : selectedGameName === "Black Jack" && column === props.t("tablestakes") ? (
                          `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                        ) : selectedGameName === "Black Jack" && column === props.t("tableplayer") ? (
                          // `${item.total_player} / ${item.max_player}`
                          <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                            {`${item.total_player} / ${item.max_player}`}
                          </span>
                        ) : selectedGameName === "Black Jack" &&
                          column === props.t("tablemindealer") ? (
                          formatPricewithdecimal(item.min_dealer)
                        ) : //  Big Two
                          selectedGameName === "Big Two" && column === props.t("tablename") ? (
                            <>
                              {item.name}
                              <TableIconDisplay type={item.type} />
                            </>
                          ) : selectedGameName === "Big Two" && column === props.t("tablemin") ? (
                            formatPricewithdecimal(item.min_buyin)
                          ) : selectedGameName === "Big Two" && column === props.t("tablestakes") ? (
                            formatPricewithdecimal(item.stake)
                          ) : selectedGameName === "Big Two" && column === props.t("tableplayer") ? (
                            // `${item.total_player} / ${item.max_player}`
                            <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                              {`${item.total_player} / ${item.max_player}`}
                            </span>
                          ) : //  Super 10
                            selectedGameName === "Super 10" && column === props.t("tablename") ? (
                              <>
                                {item.name}
                                <TableIconDisplay type={item.type} />
                              </>
                            ) : selectedGameName === "Super 10" &&
                              column === props.t("tableminmax") ? (
                              `${formatPricewithdecimal(item.min_buyin)} / ${formatPricewithdecimal(item.max_buyin)}`
                            ) : selectedGameName === "Super 10" && column === props.t("tableblind") ? (
                              formatPricewithdecimal(item.stake)
                            ) : selectedGameName === "Super 10" && column === props.t("tableplayer") ? (
                              // `${item.total_player} / ${item.max_player}`
                              <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                                {`${item.total_player} / ${item.max_player}`}
                              </span>
                            ) : //  Omaha Poker
                              selectedGameName === "Omaha Poker" && column === props.t("tablename") ? (
                                <>
                                  {item.name}
                                  <TableIconDisplay type={item.type} />
                                </>
                              ) : selectedGameName === "Omaha Poker" &&
                                column === props.t("tableminmax") ? (
                                `${formatPricewithdecimal(item.min_buyin)} / ${formatPricewithdecimal(item.max_buyin)}`
                              ) : selectedGameName === "Omaha Poker" && column === props.t("tableblind") ? (
                                `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                              ) : selectedGameName === "Omaha Poker" && column === props.t("tableplayer") ? (
                                // `${item.total_player} / ${item.max_player}`
                                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                                  {`${item.total_player} / ${item.max_player}`}
                                </span>
                              ) : //  Three Kings
                                selectedGameName === "Three Kings" && column === props.t("tablename") ? (
                                  <>
                                    {item.name}
                                    <TableIconDisplay type={item.type} />
                                  </>
                                ) : selectedGameName === "Three Kings" && column === props.t("tablemin") ? (
                                  formatPricewithdecimal(item.min_buyin)
                                ) : selectedGameName === "Three Kings" && column === props.t("tablestakes") ? (
                                  `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                                ) : selectedGameName === "Three Kings" && column === props.t("tableplayer") ? (
                                  // `${item.total_player} / ${item.max_player}`
                                  <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                                    {`${item.total_player} / ${item.max_player}`}
                                  </span>
                                ) : selectedGameName === "Three Kings" &&
                                  column === props.t("tablemindealer") ? (
                                  formatPricewithdecimal(item.min_dealer)
                                ) : //  Joker Dealer
                                  selectedGameName === "Joker Dealer" && column === props.t("tablename") ? (
                                    <>
                                      {item.name}
                                      <TableIconDisplay type={item.type} />
                                    </>
                                  ) : selectedGameName === "Joker Dealer" &&
                                    column === props.t("tablemin") ? (
                                    formatPricewithdecimal(item.min_buyin)
                                  ) : selectedGameName === "Joker Dealer" && column === props.t("tablestakes") ? (
                                    `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                                  ) : selectedGameName === "Joker Dealer" && column === props.t("tableplayer") ? (
                                    // `${item.total_player} / ${item.max_player}`
                                    <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                                      {`${item.total_player} / ${item.max_player}`}
                                    </span>
                                  ) : selectedGameName === "Joker Dealer" &&
                                    column === props.t("tablemindealer") ? (
                                    formatPricewithdecimal(item.min_dealer)
                                  ) : //  Adu-Q
                                    selectedGameName === "Adu-Q" && column === props.t("tablename") ? (
                                      <>
                                        {item.name}
                                        <TableIconDisplay type={item.type} />
                                      </>
                                    ) : selectedGameName === "Adu-Q" && column === props.t("tablemin") ? (
                                      formatPricewithdecimal(item.min_buyin)
                                    ) : selectedGameName === "Adu-Q" && column === props.t("tablestakes") ? (
                                      `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                                    ) : selectedGameName === "Adu-Q" && column === props.t("tableplayer") ? (
                                      // `${item.total_player} / ${item.max_player}`
                                      <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                                        {`${item.total_player} / ${item.max_player}`}
                                      </span>
                                    ) : //  Bull Bull Dice
                                      selectedGameName === "Bull Bull Dice" && column === props.t("tablename") ? (
                                        <>
                                          {item.name}
                                          <TableIconDisplay type={item.type} />
                                        </>
                                      ) : selectedGameName === "Bull Bull Dice" &&
                                        column === props.t("tableminmax") ? (
                                        `${formatPricewithdecimal(item.min_buyin)} / ${formatPricewithdecimal(item.max_buyin)}`
                                      ) : selectedGameName === "Bull Bull Dice" &&
                                        column === props.t("tablestakes") ? (
                                        `${formatPricewithdecimal(item.sblind)} / ${formatPricewithdecimal(item.bblind)}`
                                      ) : selectedGameName === "Bull Bull Dice" &&
                                        column === props.t("tableplayer") ? (
                                        // `${item.total_player} / ${item.max_player}`
                                        <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                                          {`${item.total_player} / ${item.max_player}`}
                                        </span>
                                      ) : //  Baccarat
                                        selectedGameName === "Baccarat" && column === props.t("tablename") ? (
                                          <>
                                            {item.name}
                                            <TableIconDisplay type={item.type} />
                                          </>
                                        ) : selectedGameName === "Baccarat" && column === props.t("tablemin") ? (
                                          formatPricewithdecimal(item.min_buyin)
                                        ) : selectedGameName === "Baccarat" && column === props.t("tablestakes") ? (
                                          `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                                        ) : selectedGameName === "Baccarat" && column === props.t("tableplayer") ? (
                                          // `${item.total_player} / ${item.max_player}`
                                          <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                                            {`${item.total_player} / ${item.max_player}`}
                                          </span>
                                        ) : selectedGameName === "Baccarat" && column === props.t("tablemindealer") ? (
                                          formatPricewithdecimal(item.min_dealer)
                                        ) : //  Bandar QQ
                                          selectedGameName === "Bandar QQ" && column === props.t("tablename") ? (
                                            <>
                                              {item.name}
                                              <TableIconDisplay type={item.type} />
                                            </>
                                          ) : selectedGameName === "Bandar QQ" && column === props.t("tablemin") ? (
                                            formatPricewithdecimal(item.min_buyin)
                                          ) : selectedGameName === "Bandar QQ" && column === props.t("tablestakes") ? (
                                            `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                                          ) : selectedGameName === "Bandar QQ" && column === props.t("tableplayer") ? (
                                            // `${item.total_player} / ${item.max_player}`
                                            <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                                              {`${item.total_player} / ${item.max_player}`}
                                            </span>
                                          ) : selectedGameName === "Bandar QQ" &&
                                            column === props.t("tablemindealer") ? (
                                            formatPricewithdecimal(item.min_dealer)
                                          ) : //  Gaple
                                            selectedGameName === "Gaple" && column === props.t("tablename") ? (
                                              <>
                                                {item.name}
                                                <TableIconDisplay type={item.type} />
                                              </>
                                            ) : selectedGameName === "Gaple" && column === props.t("tablemin") ? (
                                              formatPricewithdecimal(item.min_buyin)
                                            ) : selectedGameName === "Gaple" && column === props.t("tablestakes") ? (
                                              `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                                            ) : selectedGameName === "Gaple" && column === props.t("tableplayer") ? (
                                              // `${item.total_player} / ${item.max_player}`
                                              <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                                                {`${item.total_player} / ${item.max_player}`}
                                              </span>
                                            ) : //  Dadu Poker
                                              selectedGameName === "Dadu Poker" && column === props.t("tablename") ? (
                                                <>
                                                  {item.name}
                                                  <TableIconDisplay type={item.type} />
                                                </>
                                              ) : selectedGameName === "Dadu Poker" && column === props.t("tablemin") ? (
                                                formatPricewithdecimal(item.min_buyin)
                                              ) : selectedGameName === "Dadu Poker" && column === props.t("tablestakes") ? (
                                                `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                                              ) : selectedGameName === "Dadu Poker" && column === props.t("tableplayer") ? (
                                                // `${item.total_player} / ${item.max_player}`
                                                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                                                  {`${item.total_player} / ${item.max_player}`}
                                                </span>
                                              ) : //  Rokak Uruk
                                                selectedGameName === "Rokak Uruk" && column === props.t("tablename") ? (
                                                  <>
                                                    {item.name}
                                                    <TableIconDisplay type={item.type} />
                                                  </>
                                                ) : selectedGameName === "Rokak Uruk" && column === props.t("tablemin") ? (
                                                  formatPricewithdecimal(item.min_buyin)
                                                ) : selectedGameName === "Rokak Uruk" && column === props.t("tablestakes") ? (
                                                  `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                                                ) : selectedGameName === "Rokak Uruk" && column === props.t("tableplayer") ? (
                                                  // `${item.total_player} / ${item.max_player}`
                                                  <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                                                    {`${item.total_player} / ${item.max_player}`}
                                                  </span>
                                                ) : selectedGameName === "Rokak Uruk" &&
                                                  column === props.t("tablemindealer") ? (
                                                  formatPricewithdecimal(item.min_dealer)
                                                ) : //  Jisong
                                                  selectedGameName === "Jisong" && column === props.t("tablename") ? (
                                                    <>
                                                      {item.name}
                                                      <TableIconDisplay type={item.type} />
                                                    </>
                                                  ) : selectedGameName === "Jisong" && column === props.t("tableminmax") ? (
                                                    `${formatPricewithdecimal(item.min_buyin)} / ${formatPricewithdecimal(item.max_buyin)}`
                                                  ) : selectedGameName === "Jisong" && column === props.t("tablestakes") ? (
                                                    // `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                                                    `${formatPricewithdecimal(item.sblind * 1.5)}`
                                                  ) : selectedGameName === "Jisong" && column === props.t("tableplayer") ? (
                                                    // `${item.total_player} / ${item.max_player}`
                                                    <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                                                      {`${item.total_player} / ${item.max_player}`}
                                                    </span>
                                                  ) : //  Texas Poker Classic
                                                    selectedGameName === "Texas Poker Classic" &&
                                                      column === props.t("tablename") ? (
                                                      <>
                                                        {item.name}
                                                        <TableIconDisplay type={item.type} />
                                                      </>
                                                    ) : selectedGameName === "Texas Poker Classic" &&
                                                      column === props.t("tableminmax") ? (
                                                      `${formatPricewithdecimal(item.min_buyin)} / ${formatPricewithdecimal(item.max_buyin)}`
                                                    ) : selectedGameName === "Texas Poker Classic" &&
                                                      column === props.t("tableblind") ? (
                                                      `${formatPricewithdecimal(item.sblind)} / ${formatPricewithdecimal(item.bblind)}`
                                                    ) : selectedGameName === "Texas Poker Classic" &&
                                                      column === props.t("tableplayer") ? (
                                                      `${item.total_player} / ${item.max_player}`
                                                    ) : selectedGameName === "Texas Poker Classic" &&
                                                      column === "Jackpot" ? (
                                                      formatPricewithdecimal(item.jackpot)
                                                    ) : (
                                                      ""
                                                    )
            }
          </td>
        ))
        }
        <td>
          {item.total_player === item.max_player ? (
            <button className="btn btn_red">{props.t("tablefull")}</button>
          ) : (
            <button className="btn btn_yellow" onClick={() => launchRoom(gameCode, item.table_id)}>{props.t("tableplay")}</button>
          )}
        </td>
      </tr >
    ));
  };


  const renderSpecialTableRows = () => {
    if (sortedTableData.length === 0) {
      return (
        <tr>
          <td
            colSpan={specialColumns.length + 1}
            style={{ textAlign: "center" }}
          >
            {props.t("data_not_found")}
          </td>
        </tr>
      );
    }

    return sortedTableData.map((item, index) => (

      <tr key={index}>
        {specialColumns.map((column, colIndex) => (

          <td key={colIndex} className="">
            {
              // Bola Tangkas
              selectedGameName === "Bola Tangkas" && column === "No." ? (
                index + 1
              ) : selectedGameName === "Bola Tangkas" && column === props.t("user") ? (
                item.nickname
              ) : selectedGameName === "Bola Tangkas" && column === "Royal" ? (
                formatPricewithdecimal(item.bonus_royal)
              ) : selectedGameName === "Bola Tangkas" && column === "5K" ? (
                formatPricewithdecimal(item.bonus_5k)
              ) : selectedGameName === "Bola Tangkas" && column === "STRF" ? (
                formatPricewithdecimal(item.bonus_str)
              ) : selectedGameName === "Bola Tangkas" && column === "4K" ? (
                formatPricewithdecimal(item.bonus_4k)
              ) :
                selectedGameName === "Bola Tangkas" && column === props.t("tablehistory") ? (
                  <span
                    onClick={() => handlePopupOpen(item.table_id)}
                    className="Player_text"
                  >
                    {item.prize_history.split("  ").map((word, wordIndex) => {
                      const listPrize = [
                        "str", "mstr", "strf", "mstrf", "jpstr", "5k", "m5k", "5kf", "m5kf",
                        "jp5k", "royal", "mroyal", "royalf", "mroyalf", "jproyal", "jprf"
                      ];
                      const isPrizeIncluded = listPrize.includes(word.toLowerCase());
                      return (
                        <span
                          key={wordIndex}
                          id={item.table_id}
                          className={isPrizeIncluded ? 'Player_blue_text' : 'Player_text'}
                          onClick={() => handlePopupOpen(item.table_id)}
                        >
                          {word}{" "}
                        </span>
                      );
                    })}
                  </span>
                ) :


                  // Tangkas Classic
                  selectedGameName === "Tangkas Classic" && column === "No." ? (
                    index + 1
                  ) : selectedGameName === "Tangkas Classic" &&
                    column === props.t("user") ? (
                    item.nickname
                  ) : selectedGameName === "Tangkas Classic" &&
                    column === "Royal" ? (
                    formatPricewithdecimal(item.bonus_royal)
                  ) : selectedGameName === "Tangkas Classic" && column === "5K" ? (
                    formatPricewithdecimal(item.bonus_5k)
                  ) : selectedGameName === "Tangkas Classic" &&
                    column === "STRF" ? (
                    formatPricewithdecimal(item.bonus_str)
                  ) : selectedGameName === "Tangkas Classic" && column === "4K" ? (
                    formatPricewithdecimal(item.bonus_4k)
                  ) : selectedGameName === "Tangkas Classic" &&
                    column === props.t("tablehistory") ? (
                    <span
                      onClick={() => handlePopupOpen(item.table_id)}
                      className="Player_text"
                    >
                      {item.prize_history.split("  ").map((word, wordIndex) => {
                        const listPrize = [
                          "str", "mstr", "strf", "mstrf", "jpstr", "5k", "m5k", "5kf", "m5kf",
                          "jp5k", "royal", "mroyal", "royalf", "mroyalf", "jproyal", "jprf"
                        ];
                        // Check if the current word is in the listPrize array
                        const isPrizeIncluded = listPrize.includes(word.toLowerCase());
                        return (
                          <span
                            key={wordIndex}
                            id={item.table_id}
                            className={isPrizeIncluded ? 'Player_blue_text' : 'Player_text'}
                            onClick={() => handlePopupOpen(item.table_id)}
                          >
                            {word}{" "}
                          </span>
                        );
                      })}
                    </span>
                  ) : (
                    " "
                  )
            }
          </td>
        ))}
        <td>
          {item.nickname !== null ? (
            <button className="btn btn_red" onClick={handleFullRoom}>{props.t("tablefull")}</button>
          ) : (
            <button className="btn btn_yellow" onClick={() => launchRoom(gameCode, item.table_id)}>{props.t("tableplay")}</button>
          )}
        </td>
      </tr>
    ));
  };



  return (
    <>
      <div className="inner_content">
        <div className="table content_wrapper">
          {device === "Mobile" && (
            <Jackpot selectedGameName={gameName} gameCode={gameCode} />
          )}
          <div className="middleTableBar" cellSpacing="0">
            <p>
              {gameListImages[selectedGameName] && (
                <img
                  src={gameListImages[selectedGameName]}
                  alt={selectedGameName}
                />
              )}
              {gameName}
            </p>
            {device === "Desktop" && (
              <Jackpot selectedGameName={gameName} gameCode={gameCode} />
            )}
            {selectedGameName !== "Bola Tangkas" &&
              selectedGameName !== "Tangkas Classic" && (
                <div className="middleTableRow_checklist">
                  <div className="checklist_checkbox">
                    <input
                      type="checkbox"
                      checked={hideFull}
                      onChange={() => setHideFull(!hideFull)}
                    />
                    <span>{props.t("hide_full")}</span>
                  </div>
                  <div className="checklist_checkbox">
                    <input
                      type="checkbox"
                      checked={hideEmpty}
                      onChange={() => setHideEmpty(!hideEmpty)}
                    />
                    <span>{props.t("hide_empty")}</span>
                  </div>
                </div>
              )}

            {(selectedGameName === "Bola Tangkas" ||
              selectedGameName === "Tangkas Classic") && (
                <div className="middleTableRow_checklist">
                  <div className="checklist_checkbox">
                    <input
                      type="checkbox"
                      checked={tangkasHideFull}
                      onChange={() => setTangkasHideFull(!tangkasHideFull)}
                    />
                    <span>{props.t("hide_full")}</span>
                  </div>
                </div>
              )}
          </div>

          {gameName !== "Bola Tangkas" && gameName !== "Tangkas Classic" && (
            <div className="topTableBar">
              {tabData.length > 0 && (
                <>
                  <div
                    className={`topTableBarIcon ${roomName === "All" ? "active" : ""
                      }`}
                    onClick={() => setRoomName("All")}
                  >
                    All
                  </div>
                  {tabData.map((room, index) => (
                    <div
                      key={index}
                      className={`topTableBarIcon ${roomName === room ? "active" : ""
                        }`}
                      onClick={() => setRoomName(room)}
                    >
                      {room}
                    </div>
                  ))}
                </>
              )}
            </div>
          )}

          {gameName === "Bola Tangkas" || gameName === "Tangkas Classic" ? (
            <div className="selectedGameTable">
              <div className="topTableBar">
                {specialtabData.length > 0 ? (
                  <>
                    <div
                      className={`topTableBarIcon ${roomName === "All" ? "active" : ""
                        }`}
                      onClick={() => setRoomName("All")}
                    >
                      All
                    </div>
                    {specialtabData.map((room, index) => (
                      <div
                        key={index}
                        className={`topTableBarIcon ${roomName === room.name ? "active" : ""
                          }`}
                        onClick={() => setRoomName(room.name)}
                      >
                        {room.name}
                      </div>
                    ))}
                  </>
                ) : (
                  <div
                    className={`topTableBarIcon ${roomName === "All" ? "active" : ""
                      }`}
                    onClick={() => setRoomName("All")}
                  >
                    All
                  </div>
                )}
              </div>
              <div className="table_container">
                <table className="dataTableMenuBar" border="0">
                  <thead>
                    <tr>
                      {specialColumns.map((column, index) => (
                        <th
                          key={index}
                          onClick={() => handleFilterSpecial(column)}
                        >
                          {column === "No." ? (
                            <div
                              style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                            >
                              {column}
                              {isTrue ? (
                                <VscChevronDown size={20} />
                              ) : (
                                <VscChevronUp size={20} />
                              )}
                            </div>
                          ) : column === props.t("user") ? (
                            <div
                              style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                            >
                              {column}
                              {isTrue ? (
                                <VscChevronDown size={20} />
                              ) : (
                                <VscChevronUp size={20} />
                              )}
                            </div>
                          ) : column === "Royal" ? (
                            <div
                              style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                            >
                              {column}
                              {isTrue ? (
                                <VscChevronDown size={20} />
                              ) : (
                                <VscChevronUp size={20} />
                              )}
                            </div>
                          ) : column === "5K" ? (
                            <div
                              style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                            >
                              {column}
                              {isTrue ? (
                                <VscChevronDown size={20} />
                              ) : (
                                <VscChevronUp size={20} />
                              )}
                            </div>
                          ) : column === "STRF" ? (
                            <div
                              style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                            >
                              {column}
                              {isTrue ? (
                                <VscChevronDown size={20} />
                              ) : (
                                <VscChevronUp size={20} />
                              )}
                            </div>
                          ) : column === "4K" ? (
                            <div
                              style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                            >
                              {column}
                              {isTrue ? (
                                <VscChevronDown size={20} />
                              ) : (
                                <VscChevronUp size={20} />
                              )}
                            </div>
                          ) : column === props.t("tablehistory") ? (
                            <div
                              style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                            >
                              {column}
                              {isTrue ? (
                                <VscChevronDown size={20} />
                              ) : (
                                <VscChevronUp size={20} />
                              )}
                            </div>
                          ) : (
                            column
                          )}
                        </th>
                      ))}
                      <th onClick={handleRefreshClick}>
                        <img
                          src={refreshBtn}
                          alt=""
                          className={`coin_refresh ${isItems ? "coinRotation" : ""
                            }`}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderSpecialTableRows()}</tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="table_container">
              <table className="dataTableMenuBar" border="0">
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={index} onClick={() => handleFilter(column)}>
                        {column === props.t("tablename") ? (
                          <div
                            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : column === props.t("tableminmax") ? (
                          <div
                            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : column === props.t("tablemin") ? (
                          <div
                            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : column === props.t("tablestakes") ? (
                          <div
                            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : column === props.t("tablemindealer") ? (
                          <div
                            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : column === props.t("tableblind") ? (
                          <div
                            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : column === props.t("tableplayer") ? (
                          <div
                            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : column === "Jackpot" ? (
                          <div
                            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : (
                          column
                        )}
                      </th>
                    ))}
                    <th onClick={handleRefreshClick}>
                      <img
                        src={refreshBtn}
                        alt=""
                        className={`coin_refresh ${isItems ? "coinRotation" : ""
                          }`}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>{renderTableRows()}</tbody>
              </table>
            </div>
          )}
        </div>

        {resultPoupOpen && (
          <ResultPopup
            onClose={handleClose}
            tableid={selectedTableId}
            gameid={gameId}
          />
        )}
        {/* vip player popup */}
        {playerPopupOpen && (
          <PlayerPopup
            onClose={handleClosePopup}
            tableid={selectedTableId}
            gameid={gameId}
          />
        )}
        {roomFullPopup && (
          <>
            <div className="overlay-div" onClick={() => setRoomFullPopup(false)}></div>
            <div className="modal_popup result_popup">
              <div className="modal_popup_header">
                <h2 className="heading_1">{props.t("message")}</h2>
                <button className="modal_popup_close" onClick={() => setRoomFullPopup(false)}>
                  <img src={CloseIcon} alt="Close" />
                </button>
              </div>
              <div className="result_container">
                <div className="table_content_container">
                  <div className="result_data_main">
                    <p>{props.t('max_room_is_reach')}</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {showRoomPopup && (
          <div className="room_popup">
            <Modal
              modalHeader={true}
              modalTitle="Information"
              onCloseModal={() => setShowRoomPopup(false)}
              className="room_popup"
            >
              <div className="room_content">
              <p dangerouslySetInnerHTML={{ __html: gameConfirmMessage }}></p>
                <div className="btns">
                  <button className="btn btn-disabled" onClick={() => launchRoom(gameCode, newTableId)}>Current Room</button> &nbsp;
                  <button className="btn btn-blue" onClick={() => launchRoom(gameCode, currentTableId)}>New Room</button>
                </div>
              </div>
            </Modal>
          </div>
        )}

      </div>
    </>
  );
};

export default withTranslation()(TableDataInfo);
