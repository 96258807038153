import React, { useState } from "react";
import LangIcon from "../../assets/images/icons/navigation/lang_icon.svg";
import CloseIcon from "../../assets/images/icons/navigation/cross_icon.svg";
import EnglandFlag from "../../../src/assets/images/Lang/english_flag.svg";
import IndonesiaFlag from "../../../src/assets/images/Lang/indonesia_flag.svg";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../helpers/global_helper";
import i18next from "i18next";
import { withTranslation } from "react-i18next";

const Language = (props) => {
  const { onClose, onLanguageChange } = props;
  const defaultLanguage = localStorage.getItem("I18N_LANGUAGE") || "id";
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const updateUserLanguage = (lang) => {
    let reqData = {
      lang: lang,
    };
    makeAjax(
      url.PLAYER_API.updateUserLanguage,
      reqData,
      url.PLAYER_MS_EXT
    ).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      localStorage.setItem("I18N_LANGUAGE", lang);
      i18next.changeLanguage(lang);
      setSelectedLanguage(lang);
      onLanguageChange(lang);
      onClose();
    });
  };

  return (
    <>
      <div className="lang_dp_main">
        <div className="lang_dp_overlay" onClick={onClose}></div>
        <div className="lang_dp_container">
          <div className="lang_dp_header">
            <img src={LangIcon} alt="" />
            <p>{props.t("language")}</p>
            <img
              src={CloseIcon}
              className="lang_close_icon"
              alt=""
              onClick={onClose}
            />
          </div>
          {selectedLanguage !== "id" && (
            <div
              className="lang_dp_row"
              onClick={() => {
                updateUserLanguage("id");
              }}
            >
              <img src={IndonesiaFlag} alt="" />
              <p>Indonesia</p>
            </div>
          )}
          {selectedLanguage !== "en" && (
            <div
              className="lang_dp_row"
              onClick={() => {
                updateUserLanguage("en");
              }}
            >
              <img src={EnglandFlag} alt="" />
              <p>English</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Language.defaultProps = {
  onLanguageChange: () => { },
};

export default withTranslation()(Language);
