import React, { useEffect, useState } from "react";
import icon from "../../../../assets/images/icons/chip_icon.svg";
import refresh_icon from "../../../../assets/images/icons/refresh_icon.svg";
import refresh_iocn_bg from "../../../../assets/images/icons/refresh_btn_bg.svg";
import Modal from "../../../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getUserBalance } from "../../../../redux/actions";
import { withTranslation } from "react-i18next";
import { formatPricewithdecimal } from "../../../NumberFormat";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../../helpers/global_helper";
import CoinRewards from "../../../CoinRewards/CoinRewards";
import CoinReward from "../../../../../src/assets/images/HeadIcon/reward.svg";
import { useNavigate } from "react-router-dom";

const AccountDetail = (props) => {
  const dispatch = useDispatch();
  const user_profile = useSelector((state) => state.UserProfile);
  const user_balance = useSelector((state) => state.UserBalance.user_balance);
  const [coinRefresh, setCoinRefresh] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [totalJackpotCoins, setTotalJackpotCoins] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lastRefreshed, setLastRefreshed] = useState(null);
  const [showCoinRewardPopup, setShowCoinRewardPopup] = useState(false);
  const device = useSelector(state => state.CommonConfig.device_type);
  const navigate = useNavigate();
  // api for Total Jackpot Coins
  const fetchTotalJackpotCoins = () => {
    makeAjax(url.PLAYER_API.getTotalCoinReward, {}, url.PLAYER_MS_EXT).then(
      (response) => {
        if (response.status !== respStatus["SUCCESS"]) {
          if (response.code !== "validation_error") {
            showMessage(response);
            return;
          }
          showMessage(response);
          return;
        }
        setTotalJackpotCoins(response.data);
        setIsLoading(false);
      }
    );
  };

  const handleRefreshBalance = async () => {
    setCoinRefresh(true);
    const currentTime = new Date().getTime();
    const newClickTime = (currentTime - lastClickTime) / 1000;
    if (newClickTime < 8) {
      const remainingTime = Math.ceil(8 - newClickTime);
      setCoinRefresh(false);
      let msgName = props.t("try_again_after_8_second");
      const myArray = msgName.split("8");
      setMessage(
        myArray[0] + remainingTime + myArray[1],
        "error",
        props.t("message")
      );
      setShowAlert(true);
    } else {
      dispatch(getUserBalance());
      setLastClickTime(currentTime);
    }
  };

  const handleCoinsClick = () => {
    if (device === "Mobile") {
      navigate("/coinreward");
    } else {
      setShowCoinRewardPopup(true);
    }

  };
  useEffect(() => {
    dispatch(getUserBalance());
    fetchTotalJackpotCoins();
    const intervalId = setInterval(() => {
      dispatch(getUserBalance());
      // setCoinRefresh(new Date());
      setLastRefreshed(new Date());
      fetchTotalJackpotCoins();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <div className="profileDetail_rt_box">
        <img src={icon} alt="" />
        <p>
          {user_profile.curr} {formatPricewithdecimal(user_balance.coin)}
        </p>
        <div className="account_refresh_icon" onClick={handleRefreshBalance}>
          <img src={refresh_iocn_bg} alt="" />

          <img
            src={refresh_icon}
            alt=""
            className={`refresh_icon_img ${coinRefresh ? "coinRotation" : ""
              }`}
          />

        </div>
      </div>
      {!isLoading &&
        totalJackpotCoins !== 0 &&
        totalJackpotCoins !== "0.00" && (
          <div className="total_coin" onClick={handleCoinsClick}>
            <p>
              Total Coins:{" "}
              <span className="total_coin_txt">
                {formatPricewithdecimal(totalJackpotCoins)}
              </span>
            </p>
          </div>
        )}

      {showAlert && (
        <Modal closeButton={false} onCloseModal={() => setShowAlert(false)}>
          <div className="refreshBtn_modal_body">
            <p>{message}</p>
            <button className="btn_blue" onClick={() => setShowAlert(false)}>
              Ok
            </button>
          </div>
        </Modal>
      )}

      {showCoinRewardPopup && (
        <div className="nav_popup">
          <Modal
            modalHeader={true}
            modalTitle={props.t("reward")}
            modalSrc={CoinReward}
            onCloseModal={() => setShowCoinRewardPopup(false)}
          >
            <CoinRewards />
          </Modal>
        </div>
      )}
    </>
  );
};

export default withTranslation()(AccountDetail);
