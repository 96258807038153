import { call, put, all, takeEvery } from "redux-saga/effects";
// Login Redux States
import { USER_BALANCE } from "./actionTypes";
import { getUserBalanceSuccess } from "./actions";
import { makeAjax, showMessage, url } from "../../helpers/global_helper";

function* getUserBalance() {
  try {

    const response = yield call(
      makeAjax,
      url.PLAYER_API.getUserBalance, null, url.PLAYER_MS_EXT);
    if (response.status == 'SUCCESS') {
      yield all([
        put(getUserBalanceSuccess(response.data))
      ]);
    }
    else {
      showMessage(response);
    }
  } catch (error) {

  }
}

function* userBalanceSaga() {
  yield takeEvery(USER_BALANCE, getUserBalance);
}
export default userBalanceSaga;