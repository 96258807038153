import React, { useEffect, useState } from "react";
import AccountDetail from "./AccountDetail";
import defaultAvatar from "../../../../assets/images/profile/defult.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../redux/actions";
import ProfileModal from "./ProfileModal";
import { withTranslation } from "react-i18next";
const ProfileDetail = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isProfilePopupOpen, setisProfilePopupOpen] = useState(false);
  const [isMobile, setIsMobile] = useState();
  const device = useSelector((state) => state.CommonConfig.device_type);
  const user_profile = useSelector((state) => state.UserProfile);
  const user_nickname = useSelector((state) => state.UserProfile.user);
  useEffect(() => {
    dispatch(getUserProfile());
    function checkScreenSize() {
      if (window.innerWidth <= 991) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);
  const handleProfileClick = () => {
    if (isMobile && device === "Mobile") {
      navigate("/profile");
    } else {
      setisProfilePopupOpen(true);
    }
  };
  const handleCloseProfilePopup = () => {
    setisProfilePopupOpen(false);
  };
  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  return (
    <>
      <div className="profileDetail">
        <div className="profilePhoto" onClick={handleProfileClick}>
          <img src={user_profile.myAvatarLink || defaultAvatar} alt="Profile" />
        </div>
        <div className="profileData">
          <div className="profileDataName">
            <p>
              {user_profile && user_profile.nickname
                ? `${props.t("welcome")}, ${user_profile.nickname}`
                : `${props.t("welcome")}, User`}
            </p>
          </div>
          <AccountDetail />
          {isProfilePopupOpen && (
            <ProfileModal onClose={handleCloseProfilePopup} />
          )}
        </div>
      </div>
    </>
  );
};

export default withTranslation()(ProfileDetail);
