import React from "react";
import MainGameIcon from "./SubComponent/MainGameIcon";
import Carousel from "./Carousel/Carousel";

const Main = () => {
  return (
    <>
      <div id="main">
        <div className="container">
          <div className="main_inr">
            <div className="main_heading">
              <p className="main_heading_text">CARD GAMES</p>
            </div>
            <div className="carousel_section_mobile">
              <Carousel />
            </div>
            <div className="gamelist">
              <div className="gamelist_container_mobile">
                <MainGameIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
