import HeaderRightMenu from './SubComponent/HeaderRightMenu';
import ProfileDetail from './SubComponent/HeaderLeftMenu/ProfileDetail';
import WebNavigation from './SubComponent/HeaderLeftMenu/WebNavigation';
// import { useEffect, useState } from 'react';
// import { url, makeAjax, respStatus, showMessage } from '../../helpers/global_helper';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch } from "react-redux";
// import { setUserNameID } from "../../redux/userNameId"


const Header = () => {
    return (
        <>
            <div className="header">
                <div className='headerLeftSection'>
                    <ProfileDetail />
                </div>
                <div className='headerRightSection'>
                    <WebNavigation />
                    <HeaderRightMenu />
                </div>
            </div>
        </>
    )
}

export default Header



