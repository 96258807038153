import React from 'react'
import FooterLeftMenu from './SubComponent/FooterLeftMenu/FooterLeftMenu'
import FooterRightMenu from './SubComponent/FooterRightMenu'

const Footer = () => {


    return (
        <>
            <div className='footerBar'>
                <FooterLeftMenu />
                <FooterRightMenu />

            </div>
        </>
    )
}

export default Footer