import React, { useEffect, useState } from "react";
import CloseIcon from "../../../assets/images/modal/close.svg";
import { withTranslation } from "react-i18next";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import { formatPricewithdecimal } from "../../NumberFormat";

const ReferralBonusDatePopup = (props) => {
  const { onClose, referralbonusDateId } = props;
  const [referralbonusdatedata, setReferralBonusDateData] = useState([]);
  // api for Referral Bonus Date popup
  const fetchReferralBonusDateData = () => {
    let reqData = {
      date: referralbonusDateId,
    };
    makeAjax(url.PLAYER_API.getReferralBonus, reqData, url.PLAYER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage("server connection lost", "error", "not getting data");
            return;
          }
          showMessage("server connection lost ", "error", "not geeting data");
          return;
        }
        setReferralBonusDateData(response.data);
      }
    );
  };

  useEffect(() => {
    if (referralbonusDateId) {
      fetchReferralBonusDateData();
    }
  }, [referralbonusDateId]);

  return (
    <div className="modal_popup">
      <div className="modal_popup_header">
        {props.t("information")} Referral
        <div className="header_bottom_border"></div>
        <button className="modal_popup_close" onClick={onClose}>
          <img src={CloseIcon} alt="" />
        </button>
      </div>
      <div className="tab_content">
        <div className="table_main">
          <table className="dataTableMenuBar" border="0">
            <thead>
              <tr>
                <th>{props.t("nickname")}</th>
                <th>{props.t("turnover")}</th>
                <th> {props.t("commision")}</th>
              </tr>
            </thead>
            <tbody>
              {referralbonusdatedata === 0 ? (
                <tr className="table_data_empty_row">
                  <td colSpan="3">{props.t("data_not_found")}</td>
                </tr>
              ) : (
                <tr className="first_bg">
                  <td>{referralbonusdatedata.nickname}</td>
                  <td>{formatPricewithdecimal(referralbonusdatedata.turnover)}</td>
                  <td>
                    {referralbonusdatedata.commission
                      ? parseFloat(referralbonusdatedata.commission).toFixed(2)
                      : ""}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ReferralBonusDatePopup);
