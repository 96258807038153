import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Routes, Route } from "react-router-dom";
import { makeAjax, respStatus, showMessage, url } from "../../helpers/global_helper";

import { TOKEN_KEY } from "../../helpers/url_helper";
import NavigationComponent from "../NavigationComponent/NavigationComponent";

const LaunchLobby = (props) => {
  const { token, operatorid, username, channel } = useParams();
  let navigate = useNavigate();
  const [imageLoader, setImageLoader] = useState(null);
  const [logo, setLogo] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);

  // Function to fetch loader and logo image 
  const fetchImgData = async (url) => {
    try {
      const response = await fetch(url);
      const data = await response.text();
      // Loader Image
      const base64Regex1 = /loading_lobby\s*=\s*"([^"]*)"/;
      const match1 = data.match(base64Regex1);
      if (match1 && match1.length > 1) {
        setImageLoader(match1[1]);
      } else {
        // console.error('404 Loader Image not found');
      }
      // Logo Image
      const base64Regex2 = /logo\s*=\s*"([^"]*)"/;
      const match2 = data.match(base64Regex2);
      if (match2 && match2.length > 1) {
        setLogo(match2[1]);
      } else {
        // console.error('404 Logo Image not found');
      }
    } catch (error) {
      // console.error('Error fetching the JS file:', error);
    }
  };

  useEffect(() => {
    if (imageLoader && !hasFetched) {
      localStorage.setItem('loader', imageLoader);
      props.setLoader(imageLoader);
      setHasFetched(true);
    }
  }, [imageLoader, hasFetched]);

  // Function to get token and other session data from the API
  const getMyToken = async () => {
    let reqData = {
      token: token,
      operatorid: operatorid,
      user_name: username,
      channel: channel
    };

    const response = await makeAjax(url.PLAYER_API.addUserSession, reqData, url.PLAYER_MS_EXT);

    if (response.status !== respStatus['SUCCESS']) {
      if (response.code === "lobby_maintenance" || response.code === "inactive_partner") {
        navigate('/maintenance');
        return;
      }
      showMessage(response);
      return;
    }

    await fetchImgData(response.data.loaderJS);
    // startLoader(url.PLAYER_API.getUser);


    localStorage.setItem(TOKEN_KEY, response.data.token);
    localStorage.setItem("partner", response.data.partner);
    const nickresp = await makeAjax(url.PLAYER_API.getUser, {}, url.PLAYER_MS_EXT);
    const nickname = nickresp.data.user.nickname

    if (nickresp.status === respStatus['SUCCESS']) {
      if (nickname === '') {
        navigate("/nickname");
      // return;
      } else {
        navigate('/');
      }
    }

    // navigate('/');
    // setTimeout(() => {
    //   navigate('/');
    // }, 1000);
  };

  useEffect(() => {
    getMyToken();
  }, [token, operatorid, username]);

  return null;
};

export default LaunchLobby;
