import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import win from "../../../../assets/images/footer/medal.svg";
import reward from "../../../../assets/images/footer/jackpot.svg";
import homeIcon from "../../../../assets/images/footer/card_game_home_icon.svg";
import info from "../../../../assets/images/footer/info.svg";
import notification from "../../../../assets/images/footer/notification.svg";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const FooterLeftMenu = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user_balance = useSelector((state) => state.UserBalance.user_balance);
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    const path = location.pathname; 
    switch (path) {
      case "/coinReward":
        setActiveTab("reward");
        break;
      case "/jackpot":
        setActiveTab("jackpot");
        break;
      case "/":
        setActiveTab("home");
        break;
      case "/information":
        setActiveTab("information");
        break;
      case "/notification":
        setActiveTab("notification");
        break;
      default:
        setActiveTab(""); 
        break;
    }
  }, [location]); 

  const handleTabClick = (tab, path) => {
    setActiveTab(tab); 
    navigate(path); 
  };

  return (
    <div className="left-footInfo-bar">
      <div
        className={`IconName ${activeTab === "reward" ? "active" : ""}`}
        onClick={() => handleTabClick("reward", "/coinReward")}
      >
        <div className="footer_lefticon_img_holder">
          <img src={win} className="winGift" alt="" />
        </div>
        <p>{props.t('reward')}</p>
      </div>
      <div
        className={`IconName ${activeTab === "jackpot" ? "active" : ""}`}
        onClick={() => handleTabClick("jackpot", "/jackpot")}
      >
        <div className="footer_lefticon_img_holder">
          <img src={reward} className="rewardGift" alt="GET REWARD" />
        </div>
        <p>Jackpot</p>
      </div>
      <div
        className={`IconName ${activeTab === "home" ? "active" : ""}`}
        onClick={() => handleTabClick("home", "/")}
      >
        <div className="footer_lefticon_img_holder">
          <img src={homeIcon} className="home_footer" alt="" />
        </div>
        <p>Home</p>
      </div>
      <div
        className={`IconName ${activeTab === "information" ? "active" : ""}`}
        onClick={() => handleTabClick("information", "/information")}
      >
        <div className="footer_lefticon_img_holder">
          <img src={info} className="infoIcon" alt="" />
        </div>
        <p>{props.t('info')}</p>
      </div>
      <div
        className={`IconName ${activeTab === "notification" ? "active" : ""}`}
        onClick={() => handleTabClick("notification", "/notification")}
      >
        <div className="footer_lefticon_img_holder">
          <div className="notify_count">{user_balance.countNotif}</div>
          <img src={notification} className="notification" alt="" />
        </div>
        <p>{props.t('notify')}</p>
      </div>
    </div>
  );
};

export default withTranslation()(FooterLeftMenu);
