import React, { useEffect, useState } from "react";
import CloseIcon from "../../../assets/images/modal/close.svg";
import { withTranslation } from "react-i18next";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import { formatPricewithdecimal } from "../../NumberFormat";

const TurnoverDatePopup = (props) => {
  const { onClose, turnoverDateId } = props;
  const [turnoverinfodatedata, setTurnoverInfoDateData] = useState(null);

  // API for Turnover Bonus Date popup
  const fetchTurnoverInfoDateData = () => {
    let reqData = {
      date: turnoverDateId,
    };
    makeAjax(url.PLAYER_API.getTurnover, reqData, url.PLAYER_MS_EXT).then(
      (response) => {
        if (response.status !== respStatus["SUCCESS"]) {
          if (response.code !== "validation_error") {
            showMessage("server connection lost", "error", "not getting data");
            return;
          }
          showMessage("server connection lost", "error", "not getting data");
          return;
        }
        setTurnoverInfoDateData(response.data);
      }
    );
  };

  useEffect(() => {
    if (turnoverDateId) {
      fetchTurnoverInfoDateData();
    }
  }, [turnoverDateId]);

  return (
    <>
      <div className="modal_popup_overlay" onClick={onClose}></div>
      <div className="modal_popup  turnover_info">
        <div className="modal_popup_header">
          {props.t("info_turnover")} &nbsp;
          {props.t("win")} / {props.t("lose")}
          <div className="header_bottom_border"></div>
          <button className="modal_popup_close" onClick={onClose}>
            <img src={CloseIcon} alt="" />
          </button>
        </div>

        <div className="tab_content">
          <div className="table_main">
            <table className="dataTableMenuBar" border="0">
              <thead>
                <tr>
                  <th>{props.t("game")}</th>
                  <th className="text-center">
                    {props.t("win")} / {props.t("lose")}
                  </th>
                  <th>{props.t("turnover")}</th>
                </tr>
              </thead>
              {turnoverinfodatedata && (
                <tbody>
                  {turnoverinfodatedata.length === 0 ? (
                    <tr className="table_data_empty_row">
                      <td colSpan="3">{props.t("data_not_found")}</td>
                    </tr>
                  ) : (
                    turnoverinfodatedata.map((data, index) => (
                      <tr className="first_bg" key={index}>
                        <td>{data.gamename}</td>
                        <td>{formatPricewithdecimal(data.winlose)}</td>
                        <td>{formatPricewithdecimal(data.tover)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(TurnoverDatePopup);
