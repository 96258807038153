import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import productBlueBg from "../../../assets/images/home_main/game_item_blue_bg.svg";
import productBrownBg from "../../../assets/images/home_main/game_item_brown_bg.svg";
import productPurpleBg from "../../../assets/images/home_main/game_item_purple_bg.svg";
import promoTagBg from "../../../assets/images/home_main/promo_tag_bg.svg";
import hotTagBg from "../../../assets/images/home_main/hot.svg";
import newTagBg from "../../../assets/images/home_main/new.svg";
import underMaintenance from "../../../assets/images/home_main/maintenance.svg";
import Modal from "../../Modal/Modal";
import texasPokerImg from "../../../assets/images/GameList/Texas-poker.svg";
import dominoQqImg from "../../../assets/images/GameList/domino-qq.svg";
import cemeImg from "../../../assets/images/GameList/ceme.svg";
import cemeFighterImg from "../../../assets/images/GameList/ceme-fighter.svg";
import blackJackImg from "../../../assets/images/GameList/black-jack.svg";
import pokerDealerImg from "../../../assets/images/GameList/poker-dealer.svg";
import bolaTangkasImg from "../../../assets/images/GameList/bola-tangakas.svg";
import bigTwoImg from "../../../assets/images/GameList/big-two.svg";
import super10Img from "../../../assets/images/GameList/super-ten.svg";
import omahaPokerImg from "../../../assets/images/GameList/omaha-poker.svg";
import threeKingsImg from "../../../assets/images/GameList/three-kings.svg";
import jokerDealerImg from "../../../assets/images/GameList/joker-dealer.svg";
import aduQImg from "../../../assets/images/GameList/adu-q.svg";
import banderQqImg from "../../../assets/images/GameList/bander-qq.svg";
import gapleImg from "../../../assets/images/GameList/gaple.svg";
import pockerDiceImg from "../../../assets/images/GameList/poker-dice.svg";
import rokakUrukImg from "../../../assets/images/GameList/rokak-uruk.svg";
import jisongImg from "../../../assets/images/GameList/jisong.svg";
import texasPokerClassicImg from "../../../assets/images/GameList/Texas-Poker-Classic.svg";
import baccaratImg from "../../../assets/images/GameList/Baccarat.svg";
import bandarQqImg from "../../../assets/images/GameList/Bandar-QQ.svg";
import daduPokerImg from "../../../assets/images/GameList/Dadu-Poker.svg";
import bullBullDiceImg from "../../../assets/images/GameList/Bull-Bull-Dice.svg";
import tangkasClassicImg from "../../../assets/images/GameList/Tangkas-Classic.svg";
import { withTranslation } from "react-i18next";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import TableDataInfo from "./TableDataInfo";

const MainCardGameIcon = (props) => {
  const navigate = useNavigate();
  const device = useSelector((state) => state.CommonConfig.device_type);
  // console.log("device", { device })
  const [showModal, setShowModal] = useState(false);
  const [gameIconInRow, setgameIconInRow] = useState();
  const [iconRowClass, seticonRowClass] = useState();
  const [selectedGameName, setSelectedGameName] = useState("");
  const [selectedGameCode, setSelectedGameCode] = useState("");
  const [selectedGameId, setSelectedGameId] = useState("");
  const [gameList, setGameList] = useState([]);
  // console.log("@gameList", gameList);
  const gameListImages = {
    "Texas Poker": texasPokerImg,
    "Domino QQ": dominoQqImg,
    "Game Ceme": cemeImg,
    "Ceme Fighter": cemeFighterImg,
    "Black Jack": blackJackImg,
    "Poker Dealer": pokerDealerImg,
    "Bola Tangkas": bolaTangkasImg,
    "Big Two": bigTwoImg,
    "Super 10": super10Img,
    "Omaha Poker": omahaPokerImg,
    "Three Kings": threeKingsImg,
    "Texas Poker Classic": texasPokerClassicImg,
    "Joker Dealer": jokerDealerImg,
    "Adu-Q": aduQImg,
    "Baccarat": baccaratImg,
    "Bandar QQ": bandarQqImg,
    "Gaple": gapleImg,
    "Dadu Poker": daduPokerImg,
    "Rokak Uruk": rokakUrukImg,
    "Bull Bull Dice": bullBullDiceImg,
    "Tangkas Classic": tangkasClassicImg,
    "Jisong": jisongImg,
  };

  const featchGameList = () => {
    makeAjax(url.CARD_GAME_API.getGameList).then((response) => {
      console.log("@UserInfor Table gamelist response", response);
      if (response.status !== respStatus["SUCCESS"]) {
        if (response.code !== "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      const gameListData = response.data.gamelist.map((game) => ({
        name: game.game_name,
        code: game.game_code,
        id: game.game_id,
        image: gameListImages[game.game_name] || null,
        label_lobby: game.label_lobby || "",
      }));
      setGameList(gameListData);
      console.log(gameListData);
    });
  };

  useEffect(() => {
    featchGameList();
  }, []);

  useEffect(() => {
    function checkScreenSize() {
      if (window.innerWidth <= 600) {
        setgameIconInRow(3);
        seticonRowClass("threeInOneRow");
      } else if (window.innerWidth >= 601 && window.innerWidth <= 800) {
        setgameIconInRow(5);
        seticonRowClass("fiveInOneRow");
      } else if (window.innerWidth >= 800 && window.innerWidth <= 1030) {
        setgameIconInRow(6);
        seticonRowClass("sixInOneRow");
      } else if (window.innerWidth >= 1030) {
        setgameIconInRow(8);
        seticonRowClass("EightInOneRow");
      }
    }
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, [gameIconInRow, iconRowClass, showModal]);

  const handleClick = (gameName, gameCode, gameId) => {
    setSelectedGameName(gameName);
    setSelectedGameCode(gameCode);
    setSelectedGameId(gameId);

    if (device === "Mobile") {
      navigate('/gamedata', {
        state: {
          selectedGameName: gameName,
          gameCode: gameCode,
          gameListImages: gameListImages,
          gameId: gameId,
        },
      });
    } else {
      setShowModal(true);
    }
  };

  // Function to preload images for faster loading
  useEffect(() => {
    const preloadImages = () => {
      const imagesToLoad = Object.values(gameListImages);
      const promises = imagesToLoad.map((src) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
        });
      });

      Promise.all(promises).then(() => {
        // console.log("=All images are preloaded");
      });
    };
    preloadImages();
  }, [gameListImages]);

  return (
    <>
      {gameList.map((game, index) => (
        <div
          key={index}
          className={`game_item ${iconRowClass}`}
          onClick={() => handleClick(game.name, game.code, game.id)}
        >
          <div className="game_item_bg item_blue">
            {/* <img
              src={
                index < gameIconInRow
                  ? productBlueBg
                  : index < gameIconInRow * 2
                    ? productBrownBg
                    : index < gameIconInRow * 3
                      ? productPurpleBg
                      : index < gameIconInRow * 4
                        ? productBlueBg
                        : index < gameIconInRow * 5
                          ? productBrownBg
                          : productPurpleBg
              }
              alt=""
            /> */}
            <img
              src={
                index % (gameIconInRow * 3) < gameIconInRow
                  ? productBlueBg
                  : index % (gameIconInRow * 3) < gameIconInRow * 2
                    ? productBrownBg
                    : productPurpleBg
              }
              alt=""
            />
          </div>
          <div className="item-detail">
            <div className="game_promo_tag">
              {/* <img src={promoTagBg} alt="" /> */}
              {game.label_lobby.includes("hot") && <img src={hotTagBg} alt="Hot Tag" />}
              {game.label_lobby.includes("new") && <img src={newTagBg} alt="New Tag" />}
              {game.label_lobby.includes("promo") && <img src={promoTagBg} alt="Promo Tag" />}
            </div>
            <div className="game_under_maintenance" style={{ display: "none" }}>
              <img src={underMaintenance} alt="" />
            </div>
            <div className="game_item_imgbox">
              <div className="game_item_imgholder">
                <img
                  className="insideIconSetup"
                  src={game.image}
                  alt="Game Icon"
                  loading="lazy"
                />
              </div>
            </div>
            <div
              className="game_detail"
              onClick={(e) => handleClick(game.name)}
            >
              <p>{game.name}</p>
            </div>
          </div>
        </div>
      ))}
      {showModal && (
        <div className="game_info_modal">
          <Modal closeButton={true} onCloseModal={() => setShowModal(false)}>
            <TableDataInfo
              selectedGameName={selectedGameName}
              gameCode={selectedGameCode}
              gameListImages={gameListImages}
              gameId={selectedGameId}
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default withTranslation()(MainCardGameIcon)
