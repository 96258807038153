import { useEffect } from "react";
import InfoIcon from "../../assets/images/icons/information_sessionexpire.svg";
const SessionExpireMessage = () => {
    useEffect(() => {
        setTimeout(() =>{
          window.close()
        }, 3000)
      })
    return (
        <div className="sessionExpire-container">
            <div className="message">
                <h1>
                    <img src={InfoIcon} alt="" class="info-icon" />
                    INFORMATION
                </h1>
                <div className="header_bottom_border"></div>
                <h2>
                    ANDA TELAH LOGIN MELALUI DEVICE LAIN. SEGERA HUBUNGI <br></br>
                    ADMINISTRATOR KAMI!!!
                </h2>
                <h4>
                    Silakan hubungi Customer Service Kami Melalui Via Livechat atau Memo.<br></br>

                    Terima Kasih
                </h4>
            </div>

        </div>
    )
}
export default SessionExpireMessage;