import React, { useState, useEffect } from "react";
import { makeAjax, respStatus, url, showMessage } from "../../../helpers/global_helper";
import CloseIcon from "../../../assets/images/modal/close_iconpopup.svg";
import { resultPopupImages } from "./ResultImages";
import { withTranslation } from "react-i18next";
import { formatPricewithdecimal } from "../../NumberFormat";

const ResultPopup = (props) => {
  const { onClose, tableid, gameid } = props;
  // console.log("tableId", tableid);
  // console.log("gameId", gameid);
  const imageKeys = ["2C", "4H", "5D", "7S", "AS", "KD"];
  const images = imageKeys.map((key) => resultPopupImages[key]);
  const [tableData, setTableData] = useState([]);
  const [selectedPrize, setSelectedPrize] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    if (tableid) {
      const fetchSelectedTabData = () => {
        makeAjax(url.CARD_GAME_API.tableDetail, { gameid, tableid }).then((response) => {
          if (response.status !== respStatus["SUCCESS"]) {
            showMessage(response);
            return;
          }
          let data = response.data.data || [];
          setTableData(data);

          if (data.length > 0) {
            setSelectedPrize(data[0]);
            const cardKeys = data[0].card.split(",");
            const images = cardKeys.map((key) => resultPopupImages[key.trim()]);
            setSelectedImages(images);
          }
        });
      }
      fetchSelectedTabData();
    }
  }, [tableid, gameid])
  const handlePrizeClick = (item) => {
    setSelectedPrize(item);
    const cardKeys = item.card.split(",");
    const images = cardKeys.map((key) => resultPopupImages[key.trim()]);
    setSelectedImages(images);

    // const DCard = [ 
    //   "ac", "2c", "3c", "4c", "5c", "6c", "7c", "8c", "9c", "10c", "jc", "qc", "kc",
    //   "ad", "2d", "3d", "4d", "5d", "6d", "7d", "8d", "9d", "10d", "jd", "qd", "kd",
    //   "ah", "2h", "3h", "4h", "5h", "6h", "7h", "8h", "9h", "10h", "jh", "qh", "kh",
    //   "as", "2s", "3s", "4s", "5s", "6s", "7s", "8s", "9s", "10s", "js", "qs", "ks",
    //   "Joker-Hitam", "Joker-Merah", "b"
    // ];

    // setSelectedPrize(item);

    // const cardIndices = item.card.split(',').map(index => parseInt(index.trim()));

    // const mappedCards = cardIndices.map(index => DCard[index] || "b");
    // console.log({ mappedCards })

  }
  return (
    <>
      <div className="overlay-div" onClick={onClose}></div>
      <div className="modal_popup result_popup">
        <div className="modal_popup_header">
          <h2 className="heading_1">{props.t("tablehistory")}</h2>
          <button className="modal_popup_close" onClick={onClose}>
            <img src={CloseIcon} alt="Close" />
          </button>
        </div>

        <div className="result_container">
          <div className="card_images_container">
            {selectedImages.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`card ${index + 1}`}
                className="card_image"
              />
            ))}
          </div>
          <div className="table_content_container">
            {tableData.length > 0 ? (
              <>
                <div className="result_table_main">
                  {tableData && tableData.map((item, index) => (
                    <p
                      className={index % 2 === 0 ? "first_bg" : "second_bg"}
                      key={index}
                      onClick={() => handlePrizeClick(item)}
                    >
                      {item.prize} : {formatPricewithdecimal(item.bet)}
                    </p>
                  ))}
                </div>
                <div className="result_data_main">
                  {selectedPrize && (
                    <>
                      <p>{props.t("tablename")} : {selectedPrize.name}</p>
                      <p>{props.t("user")} : {selectedPrize.user_name}</p>
                      <p>{props.t("date")} : {selectedPrize.datetime}</p>
                      <p>{props.t("type")} : {selectedPrize.jackpot}</p>
                      <p>{props.t("bet")} : {formatPricewithdecimal(selectedPrize.bet)}</p>
                      <p>{props.t("result")} : {formatPricewithdecimal(selectedPrize.win)}</p>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className="result_data_main">
                <p>{props.t("data_not_found")}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(ResultPopup);
