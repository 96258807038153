import { useEffect, useState } from "react";
import Tabs from "./Tabs";
import Transaction from "./SubComponents/Transaction";
import MemberList from "./SubComponents/MemberList";
import ReferralBonus from "./SubComponents/ReferralBonus";
import TurnoverInfo from "./SubComponents/TurnoverInfo";
import EventBonus from "./SubComponents/EventBonus";
import { useDispatch } from "react-redux";
import { dataEntryPerPage, setPaginationData } from "../../redux/actions";
import Pagination from "../Pagination/Pagination";
import { withTranslation } from "react-i18next";

const Information = (props) => {
  const [tabId, setTabId] = useState("transaction");
  const TabList = [
    {
      title: props.t("transaction"),
      id: "transaction",
    },
    {
      title: props.t("member_list"),
      id: "member-list",
    },
    {
      title: props.t("bonus_referral"),
      id: "referral-bonus",
    },
    {
      title: props.t("info_turnover"),
      id: "turnover-info",
    },
    {
      title: props.t("bonus_event"),
      id: "event-bonus",
    },

  ];

  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState("Transaction");
  const [perPage, setPerPage] = useState(10);
  const [resetPage, setResetPage] = useState(false);

  const handlePageNo = () => {
    setResetPage(true);
    window.scrollTo(0, 0)
  };

  const handleResetPageNo = () => {
    setResetPage(false);
  };


  const handlePerRowsChange = (e) => {
    const perPageValue = e.target.value;
    setPerPage(perPageValue);
    setResetPage(true);
  };


  useEffect(() => {
    setPerPage(10);
  }, [selectedTab]);

  dispatch(dataEntryPerPage(perPage))


  useEffect(() => {
    setSelectedTab(tabId);
    dispatch(setPaginationData(tabId))
  }, [tabId])


  return (
    <>
      <div className="inner_content">
        <div className="table content_wrapper">
          <div className="inner_container">
            <div className="info-header">
              <Tabs list={TabList} tabId={tabId} setTabId={setTabId} />
              <div>
                <select className="ui search dropdown" onChange={handlePerRowsChange} value={perPage}>
                  <option value={10}>{10}</option>
                  <option value={25}>{25}</option>
                  <option value={50}>{50}</option>
                  <option value={100}>{100}</option>
                </select>
              </div>
            </div >
            {tabId === "transaction" ? <Transaction handlePageNo={handlePageNo} /> : ""}
            {tabId === "member-list" ? <MemberList /> : ""}
            {tabId === "referral-bonus" && <ReferralBonus />}
            {tabId === "turnover-info" ? <TurnoverInfo /> : ""}
            {tabId === "event-bonus" ? <EventBonus /> : ""}
          </div>
        </div>
      </div>
      <Pagination resetPage={resetPage} handleResetPageNo={handleResetPageNo} />
    </>
  );
};
export default withTranslation()(Information);
