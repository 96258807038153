import React from 'react';
import Fullhouse from "../../../../src/assets/images/jackpot_system/Full_House.png";
import FourofAKind from "../../../../src/assets/images/jackpot_system/Four_of_A_Kind.png";
import StraightFlush from "../../../../src/assets/images/jackpot_system/Straight_Flush.png";
import RoyalFlush from "../../../../src/assets/images/jackpot_system/Royal_Flush.png";
import RoyalRuby from "../../../../src/assets/images/jackpot_system/Royal_Ruby.png";
import RoyalDiamond from "../../../../src/assets/images/jackpot_system/Royal_Diamond.png";
import { withTranslation } from 'react-i18next';

function PokerClassic(props) {
    const { t } = props;
    const injectImages = (htmlString) => {
        // Replace placeholders with image tags
        const processedString = htmlString.replace(
            /\{(FullHouse|FourOfAKind|StraightFlush|RoyalFlush|RoyalRuby|RoyalDiamond)\}/g,
            (match, key) => {
                const src = images[key];
                return src ? `<img src="${src}" alt="${key}" class="jackpot_sys_info_img" />` : match;
            }
        );

        return processedString;
    };
    const images = {
        FullHouse: Fullhouse,
        FourOfAKind: FourofAKind,
        StraightFlush: StraightFlush,
        RoyalFlush: RoyalFlush,
        RoyalRuby: RoyalRuby,
        RoyalDiamond: RoyalDiamond,
    };
    const renderedString = injectImages(t('pokerclassic'));
    return (
        <div className="texas-poker-info">
            {/* {injectImages(t('pokerclassic'), images)} */}
            <div dangerouslySetInnerHTML={{ __html: renderedString }} />
        </div>
    );
}

export default withTranslation()(PokerClassic);