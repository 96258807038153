import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import CalendarIcon from "../../../assets/images/icons/calender_icon.svg";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import Modal from "../../Modal/Modal";
import { setTotalNoResult } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import DetailsMoPopup from "../InformationMoPopup/DetailsMoPopup";
import { formatPrice } from "../../NumberFormat";
import WinPopup from "../../Information/InformationPopups/WinPopup";
import BetPopup from "../../Information/InformationPopups/BetPopup";
import LosePopup from "../../Information/InformationPopups/LosePopup";
import DrawPopup from "../../Information/InformationPopups/DrawPopup";
const TransactionMo = (props) => {
  const { handlePageNo } = props;
  const dispatch = useDispatch();

  const pageNumber = useSelector((state) => state.paginationData.pageNumber);
  const perPage = useSelector((state) => state.paginationData.dataInOnePage);
  const [startDate, setStartDate] = useState(new Date());
  const [gameName, setGameName] = useState([]);
  const [transectionData, setTransectionData] = useState([]);
  const [selectGameName, setSelectGameName] = useState("0");
  const [showBetPopup, setBetShowPopup] = useState(false);
  const [showWinPopup, setWinShowPopup] = useState(false);
  const [losePopup, setLosePopup] = useState(false);
  const [drawPopup, setDrawPopup] = useState(false);
  const [detailsMoPopup, setDetailsMoPopup] = useState(false);
  const [status, setStatus] = useState("");
  const [betType, setBetType] = useState("");
  const [newDate, setNewDate] = useState("");
  const [gameId, setGameId] = useState("");
  const [game, setGame] = useState("");
  const [limitId, setLimitId] = useState("");
  const [period, setPeriod] = useState("");
  const [transectionId, setTransectionId] = useState("");
  const [historyId, setHistoryId] = useState("");
  const [datetime, setDatetime] = useState("");
  const [game_name, setGame_name] = useState("");
  const [credit, setCredit] = useState("");
  const [debit, setDebit] = useState("");
  const [coin, setCoin] = useState("");
  const [balance, setBalance] = useState("");

  const formattedDate = startDate.toISOString().split("T")[0];

  const CustomInput = ({ value, onClick }) => (
    <div style={{ position: "relative" }} className="custom_calendar">
      <input type="text" value={value} onClick={onClick} readOnly />
      <button
        onClick={onClick}
        style={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          cursor: "pointer",
        }}
      >
        <img src={CalendarIcon} alt="" />
      </button>
    </div>
  );

  const fetchGameList = () => {
    makeAjax(url.CARD_GAME_API.getGameList).then((response) => {
      console.log("game List", response);
      if (response.status !== respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setGameName(response.data.gamelist);
    });
  };

  useEffect(() => {
    fetchGameList();
  }, []);

  const handleGameSelectOptions = (e) => {
    setSelectGameName(e.target.value);
  };

  const handleBetOpen = (
    transId,
    status,
    bettype,
    gameId,
    game,
    date,
    limitId,
    period
  ) => {
    setBetShowPopup(true);
    setTransectionId(transId);
    setStatus(status);
    setBetType(bettype);
    setGameId(gameId);
    setGame(game);
    setNewDate(date);
    setLimitId(limitId);
    setPeriod(period);
  };

  const handleWinOpen = (
    transId,
    status,
    bettype,
    gameId,
    game,
    date,
    limitId,
    period
  ) => {
    setWinShowPopup(true);
    setTransectionId(transId);
    setStatus(status);
    setBetType(bettype);
    setGameId(gameId);
    setGame(game);
    setNewDate(date);
    setLimitId(limitId);
    setPeriod(period);
  };
  const handleLoseOpen = (
    transId,
    status,
    bettype,
    gameId,
    game,
    date,
    limitId,
    period
  ) => {
    setLosePopup(true);
    setTransectionId(transId);
    setStatus(status);
    setBetType(bettype);
    setGameId(gameId);
    setGame(game);
    setNewDate(date);
    setLimitId(limitId);
    setPeriod(period);
  };
  const handleDrowOpen = (
    transId,
    status,
    bettype,
    gameId,
    game,
    date,
    limitId,
    period
  ) => {
    setDrawPopup(true);
    setTransectionId(transId);
    setStatus(status);
    setBetType(bettype);
    setGameId(gameId);
    setGame(game);
    setNewDate(date);
    setLimitId(limitId);
    setPeriod(period);
  };
  const handleDetailsOpen = (
    historyId,
    datetime,
    game_name,
    period,
    status,
    credit,
    debit,
    coin,
    balance
  ) => {
    setDetailsMoPopup(true);
    setHistoryId(historyId);
    setDatetime(datetime);
    setGame_name(game_name);
    setPeriod(period);
    setStatus(status);
    setCredit(credit);
    setDebit(debit);
    setCoin(coin);
    setBalance(balance);
    document.body.style.overflow = "hidden";
  };

  const fetchTransectionList = () => {
    let reqData = {
      type: "history_results",
      page: pageNumber,
      date: formattedDate,
      game_id: parseInt(selectGameName),
      length: perPage,
    };

    makeAjax(url.PLAYER_API.listTransaction, reqData, url.PLAYER_MS_EXT).then(
      (response) => {
        if (response.status !== respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
        console.log("transaction=>", response);
        setTransectionData(response.data.dataHistory);
        dispatch(setTotalNoResult(response.data.allHistory.total));
        console.log("123456", response.data.allHistory.total);
      }
    );
  };

  useEffect(() => {
    fetchTransectionList();
    const container = document.querySelector(".table_container");
    if (container) {
      container.scrollTop = 0;
    }
  }, [perPage, pageNumber]);

  const handleSearchData = () => {
    handlePageNo();
    fetchTransectionList();
  };

  return (
    <div className="tab_content">
      <div className="filter_row_one">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="filter_row">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<CustomInput />}
              dateFormat="yyyy-MM-dd"
            />
            <select
              className="ui search dropdown"
              onChange={handleGameSelectOptions}
            >
              <option value="0">{props.t("all")}</option>
              {gameName.length > 0 &&
                gameName.map(({ game_id, game_name }) => (
                  <option key={game_id} value={game_id}>
                    {game_name}
                  </option>
                ))}
            </select>
            <button className="btn" onClick={handleSearchData}>
              {props.t("search")}
            </button>
          </div>
        </form>
      </div>
      <div className="table_main">
        <table className="dataTableMenuBar" border="0">
          <thead>
            <tr>
              <th>{props.t("date")}</th>
              <th>{props.t("game")}</th>
              <th>{props.t("period")}</th>
              <th>{props.t("status")}</th>
              <th>{props.t("balance")}</th>
              <th>{props.t("description")}</th>
            </tr>
          </thead>
          <tbody>
            {transectionData.length === 0 ? (
              <tr className="table_data_empty_row">
                <td colSpan="6">{props.t("data_not_found")}</td>
              </tr>
            ) : (
              transectionData.map((value, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "first_bg" : "second_bg"}
                >
                  <td>{value.datetime}</td>
                  <td>{value.game_name}</td>
                  <td>{value.period}</td>
                  <td>
                    {value.status === 21 ? (
                      <span
                        className="link"
                        onClick={() =>
                          handleBetOpen(
                            value.trans_id,
                            value.status,
                            value.bettype,
                            value.game_id,
                            value.game_name,
                            value.datetime,
                            value.limit_id,
                            value.period
                          )
                        }
                      >
                        {props.t("bet")}
                      </span>
                    ) : value.status === 22 ? (
                      <span
                        className="link"
                        onClick={() =>
                          handleWinOpen(
                            value.trans_id,
                            value.status,
                            value.bettype,
                            value.game_id,
                            value.game_name,
                            value.datetime,
                            value.limit_id,
                            value.period
                          )
                        }
                      >
                        {props.t("win")}
                      </span>
                    ) : value.status === 23 ? (
                      <span
                        className="link"
                        onClick={() =>
                          handleLoseOpen(
                            value.trans_id,
                            value.status,
                            value.bettype,
                            value.game_id,
                            value.game_name,
                            value.datetime,
                            value.limit_id,
                            value.period
                          )
                        }
                      >
                        {props.t("lose")}
                      </span>
                    ) : value.status === 25 ? (
                      <span
                        className="link"
                        onClick={() =>
                          handleDrowOpen(
                            value.trans_id,
                            value.status,
                            value.bettype,
                            value.game_id,
                            value.game_name,
                            value.datetime,
                            value.limit_id,
                            value.period
                          )
                        }
                      >
                        {props.t("draw")}
                      </span>
                    ) : value.status === 30 || value.status === "Jackpot" ? (
                      props.t("win_regular_jackpot")
                    ) : value.status === 24 ? (
                      "Buy Gift"
                    ) : value.status === 26 ? (
                      "Topup"
                    ) : value.status === 27 ? (
                      "Buy In"
                    ) : value.status === 28 ? (
                      "Buy Out"
                    ) : value.status === 29 ? (
                      "Buy Mega Jackpot"
                    ) : value.act === 7 ? (
                      props.t("expired_coin")
                    ) : value.status === 36 ? (
                      "Cash Out Coins"
                    ) : (
                      value.status
                    )}
                  </td>

                  <td>{formatPrice(value.balance)}</td>
                  <td>
                    <span
                      className="link"
                      onClick={() =>
                        handleDetailsOpen(
                          value.id,
                          value.datetime,
                          value.game_name,
                          value.periode,
                          value.status,
                          value.credit,
                          value.debit,
                          value.coin,
                          value.balance
                        )
                      }
                    >
                      Details
                    </span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {
        /*---Bet popup ---*/
        showBetPopup && (
          <BetPopup
            transId={transectionId}
            status={status}
            bettype={betType}
            gameId={gameId}
            date={newDate}
            limitId={limitId}
            period={period}
            onClose={() => setBetShowPopup(false)}
          />
        )
      }
      {
        /*---Win popup ---*/
        showWinPopup && (
          <WinPopup
            transId={transectionId}
            status={status}
            bettype={betType}
            gameId={gameId}
            date={newDate}
            limitId={limitId}
            period={period}
            onClose={() => setWinShowPopup(false)}
          />
        )
      }

      {
        /*---Lose popup ---*/
        losePopup && (
          <LosePopup
            transId={transectionId}
            status={status}
            bettype={betType}
            gameId={gameId}
            date={newDate}
            limitId={limitId}
            period={period}
            onClose={() => setLosePopup(false)}
          />
        )
      }

      {
        /*---drawPopup popup ---*/
        drawPopup && (
          <DrawPopup
            transId={transectionId}
            status={status}
            bettype={betType}
            gameId={gameId}
            date={newDate}
            limitId={limitId}
            period={period}
            onClose={() => setDrawPopup(false)}
          />
        )
      }
      {detailsMoPopup && (
        <Modal
          modalTitle={props.t("Details")}
          modalHeader={true}
          onCloseModal={() => setDetailsMoPopup(false)}
        >
          <DetailsMoPopup
            historyId={historyId}
            datetime={datetime}
            game_name={game_name}
            period={period}
            status={status}
            credit={credit}
            debit={debit}
            coin={coin}
            balance={balance}
          />
        </Modal>
      )}
    </div>
  );
};

export default withTranslation()(TransactionMo);
