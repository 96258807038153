

//REGISTER
export const TOKEN_KEY = "token";
export const LOBBY_KEY = "lobby-type";
export const LOBBY_MOB_PERM = "lobby_mob_type";
export const LOBBY_WEB_PERM = "lobby_web_type";
export const API_PREFIX = "";
export const APPLICATION_EXT = "/user-api";
export const CARD_GANE_MS_EXT = "/card-game-api";
export const MASTER_MS_EXT = "/master";
export const ADMIN_MS_EXT = "/admin";
export const PLAYER_MS_EXT = "/player-api";
export const LOBBY_VERSION_KEY = "lobby_app_version";

export const CARD_GAME_API = {
    lauchGame: "/launchGame",
    getGameList:"/getGameList",
    listGame:"/listGame",
    jackpot:"/jackpot",
    totalJackpot:"/totalJackpot",
    chooseRoom:"/chooseRoom",
    subGameRoomList:"/subGameRoomList",
    tableDetail:"/tableDetail",
    getJackpotGames:"/getJackpotGames",
    getGameLink:"/getGameLink"
};
export const EXCLUDE_LOADER = [
    "/getUserBalance",
    "/checkSession",
    "/getTotalCoinReward",
    "/totalJackpot",
    "/getUserEventBonusCoin"
];
export const PLAYER_API = {
    updateUserLanguage: "/updateUserLanguage",
    addUserSession: "/addUserSession",
    getUser: "/getUser",
    getUserBalance: "/getUserBalance",
    listNotification: "/listNotification",
    getNotification: "/getNotification",
    listTransaction: "/listTransaction",
    getTransaction: "/getTransaction",
    listEventBonus: "/listEventBonus",
    listTurnover: "/listTurnover",
    getTurnover: "/getTurnover",
    getHistoryDetail: "/getHistoryDetail",
    listReferral: "/listReferral",
    listReferralBonus: "/listReferralBonus",
    getReferralBonus: "/getReferralBonus",
    generateNickname: "/generateNickname",
    checkNickname: "/checkNickname",
    updateNickname: "/updateNickname",
    updateAvatar: "/updateAvatar",
    logout: "/logout",
    getFtpUrl: "/getFtpUrl",
    getAvatar: "/getAvatar",
    getCoinReward: "/getCoinReward",    
    updateReward:"/updateReward",
    listLanguageData:"/listLanguageData",
    dataSendJackpot:"/dataSendJackpot",
    getTotalCoinReward:"/getTotalCoinReward",
    checkSession: "/checkSession",
    getUserEventBonusCoin:"/getUserEventBonusCoin"
}









