// Importing white dice images
import dadu1White from "../../../assets/images/card_images/bull_dice/white/dadu1.png";
import dadu2White from "../../../assets/images/card_images/bull_dice/white/dadu2.png";
import dadu3White from "../../../assets/images/card_images/bull_dice/white/dadu3.png";
import dadu4White from "../../../assets/images/card_images/bull_dice/white/dadu4.png";
import dadu5White from "../../../assets/images/card_images/bull_dice/white/dadu5.png";
import dadu6White from "../../../assets/images/card_images/bull_dice/white/dadu6.png";

// Importing green dice images
import dadu1Green from "../../../assets/images/card_images/bull_dice/green/dadu1.png";
import dadu2Green from "../../../assets/images/card_images/bull_dice/green/dadu2.png";
import dadu3Green from "../../../assets/images/card_images/bull_dice/green/dadu3.png";
import dadu4Green from "../../../assets/images/card_images/bull_dice/green/dadu4.png";
import dadu5Green from "../../../assets/images/card_images/bull_dice/green/dadu5.png";
import dadu6Green from "../../../assets/images/card_images/bull_dice/green/dadu6.png";

// Exporting white dice images
export const BullBullWhiteImages = {
  1: dadu1White,
  2: dadu2White,
  3: dadu3White,
  4: dadu4White,
  5: dadu5White,
  6: dadu6White,
};

// Exporting green dice images
export const BullBullGreenImages = {
  1: dadu1Green,
  2: dadu2Green,
  3: dadu3Green,
  4: dadu4Green,
  5: dadu5Green,
  6: dadu6Green,
};
